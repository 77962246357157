import React, { useState, useEffect } from "react";

import { MySubtitleH4 } from "../../styled/publicTag.js";
// import { getAllStaff, getFunctions, getPushlvl, getStaff, freezeStaff } from '../../services/api.js';

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { IconPen } from "../../components/Icon";

import { Select, message, Input } from "antd";
import { getAllStation, getWpcRun } from "../../services/api";
import Title from "../../components/Custom/Title";
import CustomTable from "../../components/antdCustom/CustomTable";
import CustomModal from "../../components/antdCustom/CustomModal";
import ModalContainer from "./ModalContainer";

const MoldModify = () => {
  const innerWidth = window.innerWidth;

  const [s_AllStation_options, set_s_AllStation_options] = useState([]); // 所有工站
  const [s_AllStation, set_s_AllStation] = useState([]); // 所有工站
  const [s_editData, set_s_editData] = useState({});
  const [tableLoading, setTableLoading] = useState(false); // api尚未回來時讓ui處於加載狀態

  const [s_searchData, set_s_searchData] = useState({
    PN: "", // 料號
    woN: "", // 工單號
    wsID: "",
    lifeF: "2",
  });

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_tableData, set_s_tableData] = useState([]); // 全部員工基本資料

  const [s_searchValue, set_s_searchValue] = useState(""); // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10,
  });

  // 切換搜尋項目(工站、tab)
  const handleChange = (type, value) => {
    const tmp = { ...s_searchData, [type]: value };
    console.log("tmp = ", tmp);
    set_s_searchData(tmp);
  };

  // 第一次渲染取得所有工站資料, 並塞值給 s_searchData
  useEffect(() => {
    const call_getAllStation = async () => {
      try {
        // const res = {
        //   "status": 404,
        //   "msg": "查無途單資料",
        //   "data": null
        // }
        const res = await getAllStation();
        if (res.status === true && res.data.length > 0) {
          console.log("全部工站資料 = ", res.data);
          let options = res.data.map((item) => (
            <Select.Option key={item.wsID} value={item.wsID}>
              {item.wsNM}
            </Select.Option>
          ));
          //options.splice(0, 0, <Select.Option key='index1' value=''>全部工站</Select.Option>);
          set_s_AllStation_options(options);

          //全部工站資料(Object)
          let StationMap = res.data.reduce((prev, curr) => {
            const tmpObj = Object.assign(prev, { [curr.wsID]: curr.wsNM });
            return tmpObj;
          }, {});

          set_s_AllStation(StationMap);
          set_s_searchData({
            ...s_searchData,
            wsID: res.data[0].wsID,
          });
          //console.log('StationMap = ', StationMap)
        }
      } catch (e) {
        console.log("---------- api getAllStaffPerm error ----------");
        console.log(e);
      }
    };
    call_getAllStation();
  }, []);

  // 取得工站途單資料
  useEffect(() => {
    if (s_searchData.wsID === "") {
      set_s_tableData([]);
      return;
    }

    const call_getWpcRun = async () => {
      try {
        setTableLoading(true);

        console.log("s_searchData = ", s_searchData);
        const res = await getWpcRun(s_searchData);

        if (res.status === true && res.data.length > 0) {
          set_s_tableData(res.data);
        } else {
          set_s_tableData([]);
        }

        res.status === true ? message.success(res.msg) : message.error(res.msg);

        setTableLoading(false);
      } catch (e) {
        setTableLoading(false);

        set_s_tableData([]);
        // set_s_count_tableData([]);
      }
    };

    const timeoutID = setTimeout(() => {
      call_getWpcRun();
    }, 500);
    return () => {
      clearTimeout(timeoutID);
    };

    // call_getWpcRun();
  }, [s_searchData]);

  const tableColumns = [
    {
      title: "歷程時間",
      dataIndex: "newlifeFT",
      key: "newlifeFT",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: "線別",
      dataIndex: "wlNM",
      key: "wlNM",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: "工單",
      dataIndex: "woN",
      key: "woN",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      align: "center",
      //width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: "單別",
      dataIndex: "woType",
      key: "woType",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${80}px`,
    },
    {
      title: "途程號",
      dataIndex: "wpcSN",
      key: "wpcSN",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: "計畫產量",
      dataIndex: "planQty",
      key: "planQty",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{ display: "flex", justifyContent: "end" }}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      ),
    },
    {
      title: "前拆數量",
      dataIndex: "d_planQty",
      key: "d_planQty",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text) => <div className="flex-end">{text}</div>,
    },
    {
      title: "投產數量",
      dataIndex: "inC",
      key: "inC",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text) => <div className="flex-end">{text}</div>,
    },
    {
      title: "後拆數量",
      dataIndex: "d_inC",
      key: "d_inC",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text) => <div className="flex-end">{text}</div>,
    },
    {
      title: "不良數量",
      dataIndex: "ngC",
      key: "ngC",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text) => <div className="flex-end">{text}</div>,
    },
    {
      title: "產出數量",
      dataIndex: "okC",
      key: "inC",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text) => <div className="flex-end">{text}</div>,
    },
    {
      title: "帳差平衡",
      dataIndex: "bal",
      key: "bal",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text) => <div className="flex-end">{text}</div>,
    },
    {
      title: "途程組",
      dataIndex: "wpcSet",
      key: "wpcSet",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`,
    },
    {
      title: "插工碼",
      dataIndex: "addSN",
      key: "addSN",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`,
    },
    {
      title: "前拆碼",
      dataIndex: "prefix",
      key: "prefix",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`,
    },
    {
      title: "後拆碼",
      dataIndex: "suffix",
      key: "suffix",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`,
    },
    {
      title: "過站碼",
      dataIndex: "sqc",
      key: "sqc",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`,
    },
    {
      title: "操作",
      dataIndex: "render",
      key: "render",
      // className: 'center',
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${50 * 1.6}px`,
      render: (text, rowData, index) => {
        return {
          children: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconPen
                className="icon"
                width={30}
                height={30}
                onClick={() => {
                  set_s_showModal(true);
                  set_s_editData(rowData);
                }}
              />
            </div>
          ),
        };
      },
    },
  ];

  return (
    <>
      <Title title="模具週期修改">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            style={{ width: "180px", marginRight: "15px" }}
            size="large"
            placeholder="請輸入工單號"
            value={s_searchData.woN}
            onChange={(e) => handleChange("woN", e.target.value)}
          />
          <Input
            style={{ width: "180px", marginRight: "15px" }}
            size="large"
            placeholder="請輸入料號"
            value={s_searchData.PN}
            onChange={(e) => handleChange("PN", e.target.value)}
          />
          <Select
            style={{ marginRight: "15px", width: "150px" }}
            size="large"
            value={s_searchData.wsID}
            onChange={(value) => handleChange("wsID", value)}
          >
            {s_AllStation_options}
          </Select>
        </div>
      </Title>

      <CustomTable
        loading={tableLoading}
        scroll={{
          x: "max-content",
          y: window.innerHeight - 60 - 45 - 220 - 90 - 100,
        }}
        columns={tableColumns}
        dataSource={
          s_tableData &&
          s_tableData.length > 0 &&
          s_tableData.map((item, index) => {
            return { ...item, key: index };
          })
        }
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              page: current,
              pageSize: size,
            });
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {s_showModal ? (
        <CustomModal
          width="30%"
          title="修改模具與標準週期"
          visible={s_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
        >
          <ModalContainer
            set_s_showModal={set_s_showModal}
            s_editData={s_editData}
            set_s_editData={set_s_editData}
          />
        </CustomModal>
      ) : null}
    </>
  );
};

export default MoldModify;
