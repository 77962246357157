import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import xlsx from "xlsx"
import FileSaver from "file-saver"

import { Select, Button, DatePicker, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { IconExcel } from '../../components/Icon/index';

import moment from 'moment';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .item {
    margin-right: 15px;
    width: 150px;

    // ipad air
    @media screen and (max-width: 820px) {
      width: 130px;
    }
  }
`

const Header = (props) => {
  const { s_searchData, s_AllStation, s_AllLine, handleChange, searchStations, tableData } = props;

  const [wlOptions, setwlOptions] = useState([]);

  // 全部工站資料
  const wsOptions = s_AllStation.map(item => {
    return <Select.Option key={item.wsID} value={item.wsNM}>{item.wsNM}</Select.Option>
  });
  wsOptions.splice(0, 0, <Select.Option key='index' value=''>全部工站</Select.Option>);

  // 工線資料
  //console.log('s_AllLine = ', s_AllLine.find(item => item.wsID === s_searchData.wsID))
  
  useEffect(() => {
    const Allwl = s_AllLine.filter(item => item.wsNM === s_searchData.wsID);
    //console.log('Allwl = ', Allwl)
    if(Allwl) {
      let tmpwlOptions = Allwl.map(item => {
        return <Select.Option key={item.wlID} value={item.wlID}>{item.wlNM}</Select.Option>
      });

      tmpwlOptions.splice(0, 0, <Select.Option key='index' value=''>全部工線</Select.Option>);
      setwlOptions(tmpwlOptions)
    }
  }, [s_searchData])

  const headers = [
    {
      title: '工線',
      key: 'wlNM',
    },
    {
      title: '型號',
      key: 'spec1',
    },
    {
      title: '機種',
      key: 'spec2',
    },
    {
      title: '工單號',
      key: 'woN',
    },
    {
      title: '料號',
      key: 'PN',
    },
    {
      title: '品名',
      key: 'pdtNM',
    },
    {
      title: '模具',
      key: 'moldID',
    },
    {
      title: '模穴',
      key: 'moldcavity',
    },
    {
      title: '標準週期',
      key: 'cycletimemh',
    },
    {
      title: '實際週期',
      key: 'realcycletime',
    },
    {
      title: '良品數量',
      key: 'okC',
    },
    {
      title: '不良數量',
      key: 'ngC',
    },
    {
      title: '生產機時',
      key: 'mhC',
    },
    {
      title: '作業員',
      key: 'staffNM',
    },
    // {
    //   title: '技術員',
    //   key: 'techNM',
    // },
    {
      title: '開工時間',
      key: 'openTstr',
    },
    {
      title: '完工時間',
      key: 'closeTstr',
    },
    {
      title: '日報產出日期',
      key: 'newdatetime',
    },
    // {
    //   title: '途單歷程',
    //   key: 'lifeF',
    // },
    // {
    //   title: '歷程時間',
    //   key: 'newlifeFT',
    // },
    // {
    //   title: '工站',
    //   key: 'wsNM',
    // },
  ]
  
  
  const download = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  
    const formatData = tableData.map(singleData => {
      let temp = {};
      for (const x of headers){
        temp[x.title] = singleData[x.key]
      }
      return temp
    })
    
    const ws = xlsx.utils.json_to_sheet(formatData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
    FileSaver.saveAs(new Blob([excelBuffer],{ type: fileType }), "工線日報查詢.xlsx");
  }

  return (
    <Wrapper>
      <Input
        size="large"
        allowClear={true}
        className='item'
        placeholder="請輸入工單號"
        value={s_searchData.woN}
        onChange={(e) => handleChange("woN", e.target.value)}
      />
      <Input
        size="large"
        allowClear={true}
        className='item'
        placeholder="請輸入料號"
        value={s_searchData.PN}
        onChange={(e) => handleChange("PN", e.target.value)}
      />
      <Select 
        size='large'
        // style={{marginRight: '15px', width: '150px'}} 
        className='item'
        value={s_searchData.wsID}
        onChange={value => handleChange('wsID', value)}
      >
        {/*s_AllStation_options*/}
        {wsOptions}
      </Select>
      <Select 
        size='large'
        // style={{marginRight: '15px', width: '150px'}} 
        className='item'
        value={s_searchData.wlID}
        onChange={value => handleChange('wlID', value)}
      >
        {wlOptions}
        
      </Select>
      <DatePicker.RangePicker 
        size='large'
        // className='item'
        allowClear={false}
        value={[moment(s_searchData.startDate), moment(s_searchData.endDate)]}
        onChange={(dates, dateStrings) => {
          handleChange('time', dateStrings)
        }}
      />
      <span style={{height: '38px', cursor: 'pointer'}}>
        <IconExcel 
            style={{width: '50px', height: '38px', marginLeft: '10px', marginRight: '5px'}} 
            onClick = {download}
        />
      </span>
    </Wrapper>
  );
};

export default Header;