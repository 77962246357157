import React, { useState, useEffect } from 'react'
import styled from 'styled-components';

import { MySubtitleH4 } from '../../styled/publicTag.js';
import { Select, Input, Tag } from 'antd';
import { getAllResume, getAllWsID } from '../../services/api';
import Title from '../../components/Custom/Title';

import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';

import ModalContainer from './ModalContainer';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  // ipad air
  @media screen and (max-width: 820px) {
    display: none;
  }
`

const Header1 = styled.div`
  display: none;

  // ipad air
  @media screen and (max-width: 820px) {
    display: flex;
    margin-bottom: 15px;
  }
`

const WorkResume = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_AllWsOptions, set_s_AllWsOptions] = useState([]);      // 全部工站資料

  const [ s_is_show, set_s_is_show ] = useState(false);
  const [ s_rowData, set_s_rowData ] = useState({});

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [pageInfo, setPageInfo] = useState({
    currentPage: 4,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10,
    page: 1
  });

  const [s_searchConfig, set_s_searchConfig] = useState({
    'wsID': '',        // 站別
    'workOrder': '',   // 工單歷程
    'woN': '',         // 工單號
    'PN': '',          // 料號
    'pdtNM': '',       // 品名
    'pageSize': pageInfo.pageSize,
    'page': pageInfo.page
    //工單歷程未做

  });

  // 點擊工單號開啟對話框
  const handleClick = rowData => {
    set_s_is_show(true);
    set_s_rowData(rowData)
  }

  // 第一次渲染取得所有站別資訊
  useEffect(() => {
    const call_getAllWsID = async () => {

      const res = await getAllWsID();
      if (res.status && res.data.length > 0) {
        console.log('全部工站資料 = ', res.data)
        const options = res.data.map((item, index) => {
          return <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>;
        });
        options.splice(0, 0, <Select.Option key='index1' value=''>全部站別</Select.Option>);
        set_s_AllWsOptions(options);
      };
    };
    call_getAllWsID();  // 取得所有站別資訊
  }, []);

  // 改變 s_searchConfig 時 call 搜尋的 api, init 用預設的config搜尋
  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      //alert('call 取得工單履歷api');
      console.log('s_searchConfig = ', s_searchConfig)
      call_getAllResume();
    };

    return () => ignore = false;

  }, [s_searchConfig]);

  const call_getAllResume = async () => {
    try {
      setTableLoading(true);
      // const res = await getAllResume({
      //   ...s_searchConfig,
      //   page: 1
      // });
      const res = await getAllResume(s_searchConfig);
      console.log('res = ', res)
      if (res.status === true && res.data.woNs.length > 0) {
        // console.log('搜尋的 key = ', s_searchConfig)
        // console.log('res = ', res)
        // console.log('全部工單履歷 = ', res.data.woNs)
        set_s_tableData(res.data.woNs);

        console.log('res.data.pageInfo = ', res.data.pageInfo)

        setPageInfo({
          ...res.data.pageInfo,
          page: res.data.pageInfo.currentPage,
          // pageSize: res.data.pageInfo.pageItemNumber
        })

      }
      if (res.status === false) {
        set_s_tableData([])
        setPageInfo({
          pageItemNumber: 10,
          totalItems: 0,
          pageSize: 10,
          page: 1
        })
      }
      setTableLoading(false);
    } catch (e) {
      setTableLoading(false);
      console.log("取得工單履歷api錯誤", e);
    }

  };

  console.log('PageInfo = ', pageInfo)

  // 改變搜尋項目(Select)
  const changeSelect = (type, value) => {

    const tmpObj = {
      ...s_searchConfig,
      page: 1,
      [type]: value
    };
    console.log('tmpObj = ', tmpObj)
    set_s_searchConfig(tmpObj);
    //alert('123')
  }

  // 當輸入的文字變為空時重打api(要做延遲)
  const handleChange = (type, value) => {
    if (value === '') {
      call_getAllResume(s_searchConfig);
    };
    const tmpObj = {
      ...s_searchConfig,
      [type]: value
    };
    set_s_searchConfig(tmpObj);
  }

  // 查詢工單履歷
  const onSearch = (value, event) => {
    if (value === '') return;
    const tmpObj = {
      ...s_searchConfig,
      'text': value
    };
    set_s_searchConfig(tmpObj);
  }

  const tableColumns = [
    {
      title: '工單號',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => {
        return (
          <div
            style={{color: '#85b4e7', cursor: 'pointer'}}        
            onClick={() => handleClick(rowData)}  
          >
            {text}
          </div>
          // <Tag 
          //   color='blue' 
          //   style={{cursor: 'pointer'}}
          //   onClick={() => handleClick(rowData)}
          // >
          //   {text}
          // </Tag>
        )
      }
    },
    {
      title: '品名',
      dataIndex: 'pdtNM',
      key: 'pdtNM',
      align: 'center',
      // width: innerWidth <= 1440 ? `${150}px` : `${180}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { textAlign: 'left' }
          },
          children: (
            <div>
              {text}
            </div>
          )
        };
      }
    },
    {
      title: '機台編號',
      dataIndex: 'wlNM',
      key: 'wlNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    {
      title: '工站名稱',
      dataIndex: 'wsNM',
      key: 'wsNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`,
      render: (text)=><div className='flex-start'>{text}</div>
    },
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${180}px`
    },
    {
      title: '工單歷程',
      dataIndex: 'lifeF',
      key: 'lifeF',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`,
      render: (text, rowData, index) => {
        const key = rowData.lifeF;
        const keyMap = {
          "0": "發佈",
          "1": "登錄",
          "B": "上模中",
          "R": "上模中",
          "2": "開始生產",
          "3": "報停工",
          "4": "報復工",
          "5": "報下線",
          "6": "再上線",
          "7": "報完工",
          "9": "撤登",
          "L": "物控合格",
          "P": "質控合格",
          "W": "特採過站",
          "Q": "隔離處置",
          "G": "過站"
        };
        return keyMap[key];
      }
    },
    {
      title: '歷程時間',
      dataIndex: 'newlifeFT',
      key: 'newlifeFT',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    // {
    //   title: <span>排程完<br />工時間</span>,
    //   dataIndex: 'fff',
    //   key: 'fff',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    // },
    // {
    //   title: '料號',
    //   dataIndex: 'ggg',
    //   key: 'ggg',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    // },
    // {
    //   title: '機種名稱',
    //   dataIndex: 'hhh',
    //   key: 'hhh',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    // },
    {
      title: '投產數量',
      dataIndex: 'inC',
      key: 'inC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div className="flex-end">
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: '計劃產量',
      dataIndex: 'planQty',
      key: 'planQty',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`,
      render: (text, rowData, index) => (
        <div className="flex-end">
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: '每時產出',
      dataIndex: 'hourC',
      key: 'hourC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div className="flex-end">
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: '帳差平衡',
      dataIndex: 'bal',
      key: 'bal',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '良品數量',
      dataIndex: 'okC',
      key: 'okC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div className="flex-end">
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: '不良數量',
      dataIndex: 'ngC',
      key: 'ngC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div className="flex-end">
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    }
  ]

  return (
    <>
      <Title title='工單履歷查詢'>
        <Header>

          <Select
            style={{ marginRight: '15px', width: '150px' }}
            size='large'
            defaultValue=""
            onChange={value => changeSelect('wsID', value)}
          >
            {s_AllWsOptions}
          </Select>

          <Select
            style={{ marginRight: '15px', width: '150px' }}
            size='large'
            defaultValue=""
            onChange={value => changeSelect('lifeF', value)}
          >
            <Select.Option value=''>全部工單歷程</Select.Option>
            <Select.Option value='0'>發佈</Select.Option>
            <Select.Option value='1'>登錄</Select.Option>
            {/* <Select.Option value='B'>報開工</Select.Option> */}
            <Select.Option value='2'>開始生產</Select.Option>
            {/* <Select.Option value='3'>報停工</Select.Option>
            <Select.Option value='4'>報復工</Select.Option> */}
            {/* <Select.Option value='eee'>報下線</Select.Option>
            <Select.Option value='fff'>再上線</Select.Option> */}
            <Select.Option value='7'>報完工</Select.Option>
            {/* <Select.Option value='L'>物控</Select.Option>
            <Select.Option value='PW'>質控</Select.Option>
            <Select.Option value='G'>過站</Select.Option> */}
          </Select>

          <Input
            style={{ width: '180px', marginRight: '15px' }}
            size='large'
            placeholder='請輸入工單號'
            value={s_searchConfig.woN}
            onChange={e => handleChange('woN', e.target.value)}
          />

          <Input
            style={{ width: '180px', marginRight: '15px' }}
            size='large'
            placeholder='請輸入料號'
            value={s_searchConfig.PN}
            onChange={e => handleChange('PN', e.target.value)}
          />

          <Input
            style={{ width: '180px' }}
            size='large'
            placeholder='請輸入品名'
            value={s_searchConfig.pdtNM}
            onChange={e => handleChange('pdtNM', e.target.value)}
          />

        </Header>
      </Title>
      <Header1>

          <Select
            style={{ marginRight: '15px', width: '150px' }}
            size='large'
            defaultValue=""
            onChange={value => changeSelect('wsID', value)}
          >
            {s_AllWsOptions}
          </Select>

          <Select
            style={{ marginRight: '15px', width: '150px' }}
            size='large'
            defaultValue=""
            onChange={value => changeSelect('lifeF', value)}
          >
            <Select.Option value=''>全部工單歷程</Select.Option>
            <Select.Option value='0'>發佈</Select.Option>
            <Select.Option value='1'>登錄</Select.Option>
            <Select.Option value='B'>報開工</Select.Option>
            <Select.Option value='2'>開始生產</Select.Option>
            <Select.Option value='3'>報停工</Select.Option>
            <Select.Option value='4'>報復工</Select.Option>
            {/* <Select.Option value='eee'>報下線</Select.Option>
            <Select.Option value='fff'>再上線</Select.Option> */}
            <Select.Option value='7'>報完工</Select.Option>
            <Select.Option value='L'>物控</Select.Option>
            <Select.Option value='PW'>質控</Select.Option>
            <Select.Option value='G'>過站</Select.Option>
          </Select>

          <Input
            style={{ width: '180px', marginRight: '15px' }}
            size='large'
            placeholder='請輸入工單號'
            value={s_searchConfig.woN}
            onChange={e => handleChange('woN', e.target.value)}
          />

          <Input
            style={{ width: '180px', marginRight: '15px' }}
            size='large'
            placeholder='請輸入料號'
            value={s_searchConfig.PN}
            onChange={e => handleChange('PN', e.target.value)}
          />

          <Input
            style={{ width: '180px' }}
            size='large'
            placeholder='請輸入品名'
            value={s_searchConfig.pdtNM}
            onChange={e => handleChange('pdtNM', e.target.value)}
          />

        </Header1>
      <CustomTable
        loading={tableLoading}
        scroll={{ x: "max-content", y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData && s_tableData.length > 0 && s_tableData.map((item, index) => {
          return { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          current: pageInfo.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            console.log(current)
            // setPageInfo({
            //   ...pageInfo,
            //   page: current,
            //   pageSize: size
            // })
            set_s_searchConfig({
              ...s_searchConfig,
              page: current,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      {s_is_show ? (
        <CustomModal
          width='80%' 
          title="工單履歷明細"
          visible={s_is_show} 
          // maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_is_show(false)}
        >
          <ModalContainer
            s_rowData={s_rowData}
            set_s_rowData={set_s_rowData}
            set_s_is_show={set_s_is_show}
          />
        </CustomModal>
      ) : null}
    </>
  )
};

export default WorkResume;