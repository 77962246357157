import { instance, conn, url } from "../services/handlerAPI";

//-----------------------------------------異常看版----------------------------------------
export const getUserPerm = () => {
  return instance.get(`${conn}://${url}/api/getUserPerm`);
};

//-----------------------------------------異常看版----------------------------------------
export const getAllAbc = (_params) => {
  return instance.get(`${conn}://${url}/api/abc/getAllAbc`, {
    params: _params,
  });
};

export const judge = (data) => {
  return instance.post(`${conn}://${url}/api/abc/judge`, data);
};

//-----------------------------------------dashboard----------------------------------------

export const dashboardData = (data) => {
  return instance.post(`${conn}://${url}/api/dashboard/socket`, data);
};

//-----------------------------------------登錄----------------------------------------
export const getWsWl = () => {
  return instance.get(`${conn}://${url}/api/getWsWl`);
};

export const initPage = () => {
  return instance.get(`${conn}://${url}/api/initPage`);
};

export const login = (data) => {
  return instance.post(`${conn}://${url}/api/login`, data);
};

export const logout = () => {
  return instance.post(`${conn}://${url}/api/logout`);
};

//-----------------------------------------測試----------------------------------------
export const getAbcSelect = () => {
  return instance.get(`${conn}://${url}/api/abc/getAbcSelect`);
};

//-----------------------------------------搜尋員工基本資料----------------------------------------
export const getStaff = (id) => {
  return instance.get(`${conn}://${url}/api/getStaff?staffID=${id}`);
};

//-----------------------------------------新建員工基本資料----------------------------------------
export const postUser = (data) => {
  return instance.post(`${conn}://${url}/api/addStaff`, data);
};

//-----------------------------------------修改員工基本資料----------------------------------------
export const modifyUser = (id, data) => {
  console.log(`${conn}://${url}/api/modifyStaff`);
  return instance.post(`${conn}://${url}/api/modifyStaff?staffID=${id}`, data);
};

//-----------------------------------------刪除員工基本資料----------------------------------------
export const deleteUser = (data) => {
  return instance.post(`${conn}://${url}/api/deleteStaff?staffID=${data}`);
};

//-----------------------------------------取得全部員工資料----------------------------------------
export const getAllStaff = () => {
  return instance.get(`${conn}://${url}/api/getAllStaff`);
};

//----------------------------------------- 取得組織功能的 Options ----------------------------------------
export const getFunctions = () => {
  return instance.get(`${conn}://${url}/api/getFunctions`);
};

//----------------------------------------- 取得推播層級的 Options ----------------------------------------
export const getPushlvl = () => {
  return instance.get(`${conn}://${url}/api/getPushlvl`);
};

//----------------------------------------- 取得全部戰情登錄權限資料 ----------------------------------------
export const getAllStaffPerm = () => {
  console.log(`${conn}://${url}/api/getAllStaffPerm`);
  return instance.get(`${conn}://${url}/api/getAllStaffPerm`);
};

//----------------------------------------- 取得所有員工工號 ----------------------------------------
export const getAllStaffID = () => {
  return instance.get(`${conn}://${url}/api/getAllStaffID`);
};

//----------------------------------------- 新建戰情登錄權限 ----------------------------------------
export const addStaffPerm = (data) => {
  return instance.post(`${conn}://${url}/api/addStaffPerm`, data);
};

//----------------------------------------- 搜尋戰情登錄權限 ----------------------------------------
export const getPdsID = (id) => {
  return instance.get(`${conn}://${url}/api/getPdsID?pdsID=${id}`);
};

//----------------------------------------- 修改戰情登錄權限 ----------------------------------------
export const modifyStaffPerm = (data) => {
  return instance.post(`${conn}://${url}/api/modifyStaffPerm`, data);
};

//----------------------------------------- 刪除戰情登錄權限 ----------------------------------------
export const deleteStaffPerm = (data) => {
  return instance.post(`${conn}://${url}/api/deleteStaffPerm`, data);
};

//----------------------------------------- 取得全部報工作業權限 ----------------------------------------
export const getAllWosPerm = (id, data) => {
  //console.log(`${conn}://${url}/api/getAllWosPerm`)
  return instance.get(`${conn}://${url}/api/getAllWosPerm`);
};

//----------------------------------------- 搜尋報工作業權限 ----------------------------------------
export const getWosID = (id) => {
  return instance.get(`${conn}://${url}/api/getWosID?wosID=${id}`);
};

//----------------------------------------- 新增報工作業權限 ----------------------------------------
export const addWosPerm = (data) => {
  return instance.post(`${conn}://${url}/api/addWosPerm`, data);
};

//----------------------------------------- 刪除報工作業權限 ----------------------------------------
export const deleteWosPerm = (data) => {
  return instance.post(`${conn}://${url}/api/deleteWosPerm`, data);
};

//----------------------------------------- 修改報工作業權限 ----------------------------------------
export const modifyWosPerm = (data) => {
  return instance.post(`${conn}://${url}/api/modifyWosPerm`, data);
};

//----------------------------------------- 取得全部產基本資料 ----------------------------------------
export const getAllProduct = () => {
  return instance.get(`${conn}://${url}/api/getAllProduct`);
};

//----------------------------------------- 取得產基本資料 ----------------------------------------
export const getProduct = (searchValue) => {
  return instance.get(`${conn}://${url}/api/getProduct?PN=${searchValue}`);
};

//----------------------------------------- 取得單一產品的全部途層 ----------------------------------------
export const getWpc = (id) => {
  return instance.get(`${conn}://${url}/api/getWpc?PN=${id}`);
};

//----------------------------------------- 新增產基本資料 ----------------------------------------
export const addProduct = (data) => {
  return instance.post(`${conn}://${url}/api/addProduct`, data);
};

//----------------------------------------- 修改產基本資料 ----------------------------------------
export const modifyProduct = (id, data) => {
  console.log("id = ", id);
  return instance.post(`${conn}://${url}/api/modifyProduct?PN=${id}`, data);
};

//----------------------------------------- 凍結員工資料 ----------------------------------------
export const freezeStaff = (data) => {
  return instance.post(`${conn}://${url}/api/freezeStaff`, data);
};

//----------------------------------------- 刪除產基本資料 ----------------------------------------
export const deleteProduct = (data) => {
  return instance.post(`${conn}://${url}/api/deleteProduct`, data);
};

//----------------------------------------- 取得所有車間資料 ----------------------------------------
export const getAllPS = () => {
  return instance.get(`${conn}://${url}/api/getAllPS`);
};

//----------------------------------------- 新增車間資料 ----------------------------------------
export const addPS = (data) => {
  return instance.post(`${conn}://${url}/api/addPS`, data);
};

//----------------------------------------- 修改車間資料 ----------------------------------------
export const modifyPS = (id, data) => {
  return instance.post(`${conn}://${url}/api/modifyPS?psID=${id}`, data);
};

//----------------------------------------- 刪除車間資料 ----------------------------------------
export const deletePS = (data) => {
  return instance.post(`${conn}://${url}/api/deletePS`, data);
};

//----------------------------------------- 搜尋車間資料 ----------------------------------------
export const getPS = (id) => {
  return instance.get(`${conn}://${url}/api/getPS?psID=${id}`);
};

//----------------------------------------- 取得所有工站資料 ----------------------------------------
export const getAllStation = () => {
  return instance.get(`${conn}://${url}/api/getAllStation`);
};

//----------------------------------------- 新增工站資料 ----------------------------------------
export const addStation = (data) => {
  return instance.post(`${conn}://${url}/api/addStation`, data);
};

//----------------------------------------- 修改工站資料 ----------------------------------------
export const modifyStation = (id, data) => {
  return instance.post(`${conn}://${url}/api/modifyStation?wsID=${id}`, data);
};

//----------------------------------------- 刪除工站資料 ----------------------------------------
export const deleteStation = (data) => {
  return instance.post(`${conn}://${url}/api/deleteStation`, data);
};

//----------------------------------------- 搜尋工站資料 ----------------------------------------
export const getStation = (id) => {
  return instance.get(`${conn}://${url}/api/getStation?wsID=${id}`);
};

//----------------------------------------- 取得所有工線資料 ----------------------------------------
export const getAllLine = () => {
  return instance.get(`${conn}://${url}/api/getAllLine`);
};

//----------------------------------------- 搜尋工線資料 ----------------------------------------
export const getLine = (id) => {
  return instance.get(`${conn}://${url}/api/getLine?wlID=${id}`);
};

//----------------------------------------- 新增工線資料 ----------------------------------------
export const addLine = (data) => {
  return instance.post(`${conn}://${url}/api/addLine`, data);
};

//----------------------------------------- 刪除工線資料 ----------------------------------------
export const deleteLine = (data) => {
  return instance.post(`${conn}://${url}/api/deleteLine`, data);
};

//----------------------------------------- 修改工線資料 ----------------------------------------
export const modifyLine = (id, data) => {
  return instance.post(`${conn}://${url}/api/modifyLine?wlID=${id}`, data);
};

//----------------------------------------- 產品基本資料 - 上傳檔案 ----------------------------------------
export const productUpload = (data) => {
  return instance.post(`${conn}://${url}/api/productUpload`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//----------------------------------------- 產品途程資料 - 上傳檔案 ----------------------------------------
export const wpcUpload = (data) => {
  return instance.post(`${conn}://${url}/api/wpcUpload`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//----------------------------------------- 取得所有途程資料 ----------------------------------------
export const getAllWpc = () => {
  return instance.get(`${conn}://${url}/api/getAllWpc`);
};

//----------------------------------------- 新增途程資料 ----------------------------------------
export const addWpc = (data) => {
  return instance.post(`${conn}://${url}/api/addWpc`, data);
};

//----------------------------------------- 修改指定途程資料 ----------------------------------------
export const modifyWpc = (data) => {
  return instance.post(`${conn}://${url}/api/modifyWpc`, data);
};

//----------------------------------------- 刪除產品途程資料 ----------------------------------------
export const deleteWpc = (data) => {
  return instance.post(`${conn}://${url}/api/deleteWpc`, data);
};

//----------------------------------------- 取得所有模具資料 ----------------------------------------
export const getAllMold = (data) => {
  return instance.get(`${conn}://${url}/api/getAllMold`, { params: data });
};

//----------------------------------------- 搜尋模具資料 ----------------------------------------
export const getMold = (id) => {
  return instance.get(`${conn}://${url}/api/getMold?moldID=${id}`);
};

//----------------------------------------- 新增模具資料 ----------------------------------------
export const addMold = (data) => {
  return instance.post(`${conn}://${url}/api/addMold`, data);
};

//----------------------------------------- 修改模具資料 ----------------------------------------
export const modifyMold = (data) => {
  return instance.post(`${conn}://${url}/api/modifyMold`, data);
};

export const moldUpdate = (data) => {
  return instance.post(`${conn}://${url}/api/moldUpdate`, data);
};

//----------------------------------------- 刪除模具資料 ----------------------------------------
export const deleteMold = (data) => {
  return instance.post(`${conn}://${url}/api/deleteMold`, data);
};

//----------------------------------------- 取得全部工站履歷 ----------------------------------------
export const getAllResume = (_params) => {
  return instance.get(`${conn}://${url}/api/getAllResume`, { params: _params });
};

//----------------------------------------- 取得工站履歷明細 ----------------------------------------
export const getResumeWpc = (_params) => {
  return instance.get(`${conn}://${url}/api/getResumeWpc`, { params: _params });
};

//----------------------------------------- 取得全部工站ID ----------------------------------------
export const getAllWsID = () => {
  return instance.get(`${conn}://${url}/api/getAllWsID`);
};
//----------------------------------------- 取得在製工站資料 ----------------------------------------
export const getAllProduction = () => {
  return instance.get(`${conn}://${url}/api/getAllProduction`);
};

//----------------------------------------- 取得工站途單資料 ----------------------------------------
export const getWpcRun = (_params) => {
  const { wsID, PN, woN, tab, lifeF } = _params;
  // const { wsID, tab } = data;
  // console.log(
  //   "url = ",
  //   `${conn}://${url}/api/getWpcRun?wsID=${wsID}&lifeF=${tab}`
  // );
  return instance.get(
    `${conn}://${url}/api/getWpcRun?wsID=${wsID}&lifeF=${
      tab || lifeF
    }&PN=${PN}&woN=${woN}`
  );
  // return instance.get(`${conn}://${url}/api/getWpcRun`, { params: _params});
};

//----------------------------------------- 取得APS資料 ----------------------------------------
export const getAps = (_params) => {
  return instance.get(`${conn}://${url}/api/getAps`, { params: _params });
};

export const modifyAps = (data) => {
  return instance.post(`${conn}://${url}/api/modifyAps`, data);
};

export const toWos = (data) => {
  return instance.post(`${conn}://${url}/api/toWos`, data);
};

export const apsClose = (data) => {
  return instance.post(`${conn}://${url}/api/apsClose`, data);
};

//----------------------------------------- 取得工線日報查詢資料 ----------------------------------------
export const getReport = (data) => {
  //console.log('data = ', data)
  const { s_lifeF, startDate, endDate, wsID, wlID, pageSize, page, PN, woN } =
    data;
  return instance.get(
    `${conn}://${url}/api/getReport?lifeF=${s_lifeF}&start=${startDate}&end=${endDate}&wsID=${wsID}&wlID=${wlID}&PN=${PN}&woN=${woN}&page=${page}&pageSize=${pageSize}`
  );
};

//----------------------------------------- 設備生產狀況 ----------------------------------------
export const getProductionInfo = (_params) => {
  return instance.get(`${conn}://${url}/api/device/getData`, {
    params: _params,
  });
};

//----------------------------------------- 搜尋部門資料 ----------------------------------------
export const getDept = (id) => {
  return instance.get(`${conn}://${url}/api/getDept?deptID=${id}`);
};
//----------------------------------------- 取得全部部門資料 ----------------------------------------
export const getAllDept = () => {
  return instance.get(`${conn}://${url}/api/getAllDept`);
};
//----------------------------------------- 新增部門資料 ----------------------------------------
export const addDept = (data) => {
  return instance.post(`${conn}://${url}/api/addDept`, data);
};

//----------------------------------------- 修改部門資料 ----------------------------------------
export const modifyDept = (data) => {
  return instance.post(`${conn}://${url}/api/modifyDept`, data);
};

//----------------------------------------- 刪除部門資料 ----------------------------------------
export const deleteDept = (data) => {
  return instance.post(`${conn}://${url}/api/deleteDept`, data);
};

//----------------------------------------- 搜尋職稱資料 ----------------------------------------
export const getPost = (id) => {
  return instance.get(`${conn}://${url}/api/getPost?postID=${id}`);
};

//----------------------------------------- 取得全部職稱資料 ----------------------------------------
export const getAllPost = () => {
  return instance.get(`${conn}://${url}/api/getAllPost`);
};

//----------------------------------------- 新增職稱資料 ----------------------------------------
export const addPost = (data) => {
  return instance.post(`${conn}://${url}/api/addPost`, data);
};

//----------------------------------------- 修改職稱資料 ----------------------------------------
export const modifyPost = (data) => {
  return instance.post(`${conn}://${url}/api/modifyPost`, data);
};

//----------------------------------------- 刪除職稱資料 ----------------------------------------
export const deletePost = (data) => {
  return instance.post(`${conn}://${url}/api/deletePost`, data);
};

//----------------------------------------- 取得全部不良品資料 ----------------------------------------
export const getNgItem = (data) => {
  return instance.get(`${conn}://${url}/api/getNgItem`, { params: data });
};

//----------------------------------------- 新增不良品資料 ----------------------------------------
export const addNgItem = (data) => {
  return instance.post(`${conn}://${url}/api/addNgItem`, data);
};

//----------------------------------------- 修改不良品資料 ----------------------------------------
export const modifyNgItem = (data) => {
  return instance.post(`${conn}://${url}/api/modifyNgItem`, data);
};

//----------------------------------------- 刪除不良品資料 ----------------------------------------
export const deleteNgItem = (data) => {
  return instance.post(`${conn}://${url}/api/deleteNgItem`, data);
};

//----------------------------------------- 取得全部停工資料 ----------------------------------------
export const getStopItem = (data) => {
  return instance.get(`${conn}://${url}/api/getStopItem`, { params: data });
};

//----------------------------------------- 新增停工資料 ----------------------------------------
export const addStopItem = (data) => {
  return instance.post(`${conn}://${url}/api/addStopItem`, data);
};

//----------------------------------------- 修改停工資料 ----------------------------------------
export const modifyStopItem = (data) => {
  return instance.post(`${conn}://${url}/api/modifyStopItem`, data);
};

//----------------------------------------- 刪除停工資料 ----------------------------------------
export const deleteStopItem = (data) => {
  return instance.post(`${conn}://${url}/api/deleteStopItem`, data);
};

//----------------------------------------- 取得全部倉別資料 ----------------------------------------
export const getAllWh = () => {
  return instance.get(`${conn}://${url}/api/getAllWh`);
};

//----------------------------------------- 搜尋倉別資料 ----------------------------------------
export const getWh = (param) => {
  return instance.get(`${conn}://${url}/api/getWh`, { params: param });
};

//----------------------------------------- 新增倉別資料 ----------------------------------------
export const addWh = (data) => {
  return instance.post(`${conn}://${url}/api/addWh`, data);
};

//----------------------------------------- 修改倉別資料 ----------------------------------------
export const modifyWh = (data) => {
  return instance.post(`${conn}://${url}/api/modifyWh`, data);
};

//----------------------------------------- 刪除倉別資料 ----------------------------------------
export const deleteWh = (data) => {
  return instance.post(`${conn}://${url}/api/deleteWh`, data);
};

//----------------------------------------- 取得全部良品資料 ----------------------------------------
export const getOkItem = (data) => {
  return instance.get(`${conn}://${url}/api/getOkItem`, { params: data });
};

//----------------------------------------- 新增良品資料 ----------------------------------------
export const addOkItem = (data) => {
  return instance.post(`${conn}://${url}/api/addOkItem`, data);
};

//----------------------------------------- 修改良品資料 ----------------------------------------
export const modifyOkItem = (data) => {
  return instance.post(`${conn}://${url}/api/modifyOkItem`, data);
};

//----------------------------------------- 刪除良品資料 ----------------------------------------
export const deleteOkItem = (data) => {
  return instance.post(`${conn}://${url}/api/deleteOkItem`, data);
};

//----------------------------------------- 取得全部異常單位資料 ----------------------------------------
export const getAbnDri = (data) => {
  return instance.get(`${conn}://${url}/api/getAbnDri`);
};

//----------------------------------------- 新增異常單位資料 ----------------------------------------
export const addAbnDri = (data) => {
  return instance.post(`${conn}://${url}/api/addAbnDri`, data);
};

//----------------------------------------- 修改異常單位資料 ----------------------------------------
export const modifyAbnDri = (data) => {
  return instance.post(`${conn}://${url}/api/modifyAbnDri`, data);
};

//----------------------------------------- 刪除異常單位資料 ----------------------------------------
export const deleteAbnDri = (data) => {
  return instance.post(`${conn}://${url}/api/deleteAbnDri`, data);
};

//----------------------------------------- 取得全部異常項目資料 ----------------------------------------
export const getAbnItem = (data) => {
  return instance.get(`${conn}://${url}/api/getAbnItem`);
};

//----------------------------------------- 新增異常項目資料 ----------------------------------------
export const addAbnItem = (data) => {
  return instance.post(`${conn}://${url}/api/addAbnItem`, data);
};

//----------------------------------------- 修改異常項目資料 ----------------------------------------
export const modifyAbnItem = (data) => {
  return instance.post(`${conn}://${url}/api/modifyAbnItem`, data);
};

//----------------------------------------- 刪除異常項目資料 ----------------------------------------
export const deleteAbnItem = (data) => {
  return instance.post(`${conn}://${url}/api/deleteAbnItem`, data);
};

//----------------------------------------- 取得全部廠商基本資料 ----------------------------------------
export const getOEMsupp = (data) => {
  return instance.get(`${conn}://${url}/api/getOEMsupp`, { params: data });
};

//----------------------------------------- 新增廠商基本資料 ----------------------------------------
export const addOEMsupp = (data) => {
  return instance.post(`${conn}://${url}/api/addOEMsupp`, data);
};

//----------------------------------------- 修改廠商基本資料 ----------------------------------------
export const modifyOEMsupp = (data) => {
  return instance.post(`${conn}://${url}/api/modifyOEMsupp`, data);
};

//----------------------------------------- 刪除廠商基本資料 ----------------------------------------
export const deleteOEMsupp = (data) => {
  return instance.post(`${conn}://${url}/api/deleteOEMsupp`, data);
};

//----------------------------------------- 取得全部外包項目 ----------------------------------------
export const getOEMItem = (data) => {
  return instance.get(`${conn}://${url}/api/getOEMItem`);
};

//----------------------------------------- 新增外包項目  ----------------------------------------
export const addOEMItem = (data) => {
  return instance.post(`${conn}://${url}/api/addOEMItem`, data);
};

//----------------------------------------- 修改外包項目 ----------------------------------------
export const modifyOEMItem = (data) => {
  return instance.post(`${conn}://${url}/api/modifyOEMItem`, data);
};

//----------------------------------------- 刪除外包項目  ----------------------------------------
export const deleteOEMItem = (data) => {
  return instance.post(`${conn}://${url}/api/deleteOEMItem`, data);
};

//----------------------------------------- 取得全部委外基本資料 ----------------------------------------
export const getOEM = (data) => {
  return instance.get(`${conn}://${url}/api/getOEM`, { params: data });
};

//----------------------------------------- 新增委外基本資料  ----------------------------------------
export const addOEM = (data) => {
  return instance.post(`${conn}://${url}/api/addOEM`, data);
};

//----------------------------------------- 修改委外基本資料 ----------------------------------------
export const modifyOEM = (data) => {
  return instance.post(`${conn}://${url}/api/modifyOEM`, data);
};

//----------------------------------------- 刪除委外基本資料  ----------------------------------------
export const deleteOEM = (data) => {
  return instance.post(`${conn}://${url}/api/deleteOEM`, data);
};

//----------------------------------------- 取得全部golden途程表頭 ----------------------------------------
export const getWpcHd = (data) => {
  return instance.get(`${conn}://${url}/api/getWpcHd`, { params: data });
};

//----------------------------------------- 新增golden途程表頭  ----------------------------------------
export const addWpcHd = (data) => {
  return instance.post(`${conn}://${url}/api/addWpcHd`, data);
};

//----------------------------------------- 編輯golden途程表頭  ----------------------------------------
export const modifyWpcHd = (data) => {
  return instance.post(`${conn}://${url}/api/modifyWpcHd`, data);
};

//----------------------------------------- 刪除golden途程表頭  ----------------------------------------
export const deleteWpcHd = (data) => {
  return instance.post(`${conn}://${url}/api/deleteWpcHd`, data);
};

//----------------------------------------- 取得全部golden途程表身 ----------------------------------------
export const getWpcTb = (data) => {
  return instance.get(`${conn}://${url}/api/getWpcTb`, { params: data });
};

//----------------------------------------- 編輯golden途程表身  ----------------------------------------
export const modifyWpcTb = (data) => {
  return instance.post(`${conn}://${url}/api/modifyWpcTb`, data);
};
