import React, {useState, useEffect} from 'react'
import { MySubtitleH4 } from '../../styled/publicTag.js';
import { Button, Select, Tabs, DatePicker, message } from 'antd';
import { getAllStation, getReport, getAllLine } from '../../services/api';
import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomTabs from '../../components/antdCustom/CustomTabs';
import Header from './Header';
import moment from 'moment'


const WorkStations = () => {

  const innerWidth = window.innerWidth;

  const [s_AllStation, set_s_AllStation] = useState([]);

  const [s_AllLine, set_s_AllLine] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [s_tableData, set_s_tableData] = useState([]);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    page: 1,
    pageSize: 10
  });

  const [s_searchData, set_s_searchData] = useState({    // 要搜尋的資料
    'wsID': '',
    'PN': '',
    'woN': '',
    'wlID': '',
    's_lifeF': '0',
    'startDate': moment(new Date()).format('YYYY-MM-DD'),
    'endDate': moment(new Date()).format('YYYY-MM-DD'),
    // 'pageSize': pageInfo.pageSize,
    // 'page': pageInfo.page
  });

  // 第一次渲染取得所有工站、工線資料
  useEffect(() => {
    call_getAllLine();
    call_getAllStation();
  }, []);

  useEffect(() => {
    set_s_searchData({
      ...s_searchData,
      'wlID': ''
    })
  }, [s_searchData.wsID]);

  // 改變 s_searchData 時重新取得 UI data
  useEffect(() => {
    // 工站資料的 api 尚未回來
    if(s_AllStation.length === 0) return;

    setTableLoading(true);

    try {
      
      call_getReport();
      setTableLoading(false);

    } catch (e) {
      console.log('------------------ call_Report error !!! --------------------');
      setTableLoading(false);
      set_s_tableData([]);
    }
    //setTableLoading(false);

  }, [s_searchData]);

  // 搜尋
  const searchStations = () => {
    alert('wait api')
  }

  // 更改搜尋項目
  const handleChange = (type, value) => {
    
    let tmp = {}

    if(type === 'time') {
      tmp = { ...s_searchData, 'startDate': value[0], 'endDate': value[1] };
    } else {
      tmp = { ...s_searchData, [type]: value };
    }
    
    set_s_searchData(tmp);
  }

  // 取得所有工站資料
  const call_getAllStation = async () => {
    setTableLoading(true);
    try {
      const res = await getAllStation();
      if(res.data) {
        console.log('全部工站資料 = ', res.data)
        // let options = res.data.map(item => <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>);
        // set_s_AllStation_options(options);
        set_s_AllStation(res.data);

        set_s_searchData({
          ...s_searchData,
          'wsID': ''
        })
      }
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e);
      set_s_AllStation([]);
      setTableLoading(false);
    }

    setTableLoading(false);

  };

  // 取得所有工線資料
  const call_getAllLine = async () => {
    try {
      const res = await getAllLine();
      if(res.data.length > 0) {
        console.log('全部工線資料 = ', res.data);
        set_s_AllLine(res.data);
        
        // let options = res.data.map(item => <Select.Option key={item.wlID} value={item.wlID}>{item.wlNM}</Select.Option>);
        // options.splice(0, 0, <Select.Option key='index1' value=''>全部線別</Select.Option>);
        // set_s_AllLine_options(options);
      }
    } catch (e) {
      console.log('---------- api getAllLine error ----------');
      console.log(e);
      set_s_AllLine([]);
    }
  };

  // 取得全部工線日報資料
  const call_getReport = async () => {
    
    const res = await getReport(s_searchData);
    console.log('res = ', res)
    if(res.data) {
      console.log('res.data.reports = ', res.data.reports)
      set_s_tableData(res.data.reports);
      message.success(res.msg);
      // setPageInfo({
      //   // ...pageInfo,
      //   ...res.data.pageInfo
      // })
    } else {
      message.error(res.msg)
      set_s_tableData([])
    };
    //setTableLoading(false);

  };


  // tab的選項
  const TabsData = [
    {
      'key': 'G',
      'tabName': '過站途單'
    },
    {
      'key': '1',
      'tabName': '登錄途單'
    },
    {
      'key': 'L',
      'tabName': '物控途單'
    },
    {
      'key': 'PW',
      'tabName': '質控途單'
    },
  ]
  
  const tableHeaders = [
    {
      title: '工線',
      dataIndex: 'wlNM',
      key: 'wlNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '型號',
      dataIndex: 'spec1',
      key: 'spec1',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: '機種',
      dataIndex: 'spec2',
      key: 'spec2',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '工單號',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: '品名',
      dataIndex: 'pdtNM',
      key: 'pdtNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${200}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { textAlign: 'left' }
          },
          children: (
            <div>
              {text}
            </div>
          )
        };
      }
    },
    {
      title: '模具',
      dataIndex: 'moldID',
      key: 'moldID',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '模穴',
      dataIndex: 'moldcavity',
      key: 'moldcavity',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: <span>標準<br/>週期</span>,
      dataIndex: 'cycletimemh',
      key: 'cycletimemh',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: <span>實際<br/>週期</span>,
      dataIndex: 'realcycletime',
      key: 'realcycletime',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: <span>良品<br/>數量</span>,
      dataIndex: 'okC',
      key: 'okC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: <span>不良<br/>數量</span>,
      dataIndex: 'ngC',
      key: 'ngC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: <span>生產<br/>機時</span>,
      dataIndex: 'mhC',
      key: 'mhC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: '作業員',
      dataIndex: 'staffNM',
      key: 'staffNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    // {
    //   title: '技術員',
    //   dataIndex: 'techNM',
    //   key: 'techNM',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    // },
    {
      title: <span>開工<br/>時間</span>,
      dataIndex: 'openTstr',
      key: 'openTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: <span>完工<br/>時間</span>,
      dataIndex: 'closeTstr',
      key: 'closeTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: <span>產出<br/>日期</span>,
      dataIndex: 'newdatetime',
      key: 'newdatetime',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    // {
    //   title: '途單歷程',
    //   dataIndex: 'lifeF',
    //   key: 'lifeF',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`
    // },
    // {
    //   title: '歷程時間',
    //   dataIndex: 'newlifeFT',
    //   key: 'newlifeFT',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    // },
    // {
    //   title: '工站',
    //   dataIndex: 'wsNM',
    //   key: 'wsNM',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    // },
  ]

  // 自動調整寬度
  const tableColumns = tableHeaders.map((item)=>{
    if (item.dataIndex !== 'pdtNM'){
      item.width = `${parseInt(100/(tableHeaders.length))-1}%`
    }
    if (item.dataIndex === 'close_time'){
      item.width = `8%`
    }
    if (item.dataIndex === 'newdatetime'){
      item.width = `8%`
    }
    return item
  })

  return (
    <>
      <Title title='工線日報查詢'>
        <Header 
          s_AllStation={s_AllStation} 
          s_AllLine={s_AllLine}
          handleChange={handleChange} 
          s_searchData={s_searchData}
          searchStations={searchStations}
          tableData={s_tableData}
        />
      </Title>

      {/* <CustomTabs 
        onChange={activeKey => handleChange('s_lifeF', activeKey)}
        activeKey={s_searchData.s_lifeF}
        TabsData={TabsData}
      /> */}
      
      <CustomTable 
        loading={tableLoading}
        scroll={{ x: "max-content",y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        dataSource={s_tableData && s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      /> 
    </>
  )
};

export default WorkStations;