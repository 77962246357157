import { Select, Table } from "antd";
import React, { useEffect, useState } from "react";

import moment from "moment";
import styled from "styled-components";
import CustomModal from "../../components/antdCustom/CustomModal";
import Title from "../../components/Custom/Title";
import { getAllPS } from "../../services/api";
import { url } from "../../services/handlerAPI";
import { fontSize } from "../../styled/publicTag";
import useWebSocket from "../../utility/useWebSocket_new";
import DashBox from "./DashBox";
import Light from "./Light";
import ModalContainer from "./ModalContainer";

const innerWidth = window.innerWidth;

const Wrapper = styled.div`
  padding: 10px 20px 0px 25px;
  background-color: ${({ theme }) => theme.mainBg};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  // ipad mini
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Header1 = styled.div`
  display: none;
  // 圓角設定
  .ant-input,
  .ant-picker {
    border-radius: 15px;
  }
  // 圓角設定
  .ant-select-selector {
    border-radius: 5px !important;
  }

  // ipad mini
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
`;

const CustomTable = styled(Table)`
  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 4px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.p};
  }

  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  //table 的圓角
  .ant-table-container,
  .ant-table {
    border-radius: 10px !important;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 10px !important;
  }

  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  .ant-table-thead .ant-table-cell {
    padding: 8px;
    /* font-size: ${fontSize.text}; */
    font-size: ${({ size }) => (size ? size : fontSize.text)};
    white-space: nowrap;
  }

  .size {
    font-size: ${innerWidth <= 1440 ? "12px" : "16px"};
  }

  // 使用字級 + 4px
  .lineHeight {
    line-height: 25px;
  }

  .left {
    width: 100%;
    text-align: left;
  }
`;

function DashBoard() {
  const innerWidth = window.innerWidth;

  const [tableData, setTableData] = useState([]);

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_boxData, set_s_boxData] = useState({});

  const [s_AllStation_options, set_s_AllStation_options] = useState([]); // 所有工站

  const [s_psID, set_s_psID] = useState("B01"); // 所有工站 2022-01-14 因為要 demo 所以先鎖住指顯示生一車間

  const [dashboardDataSocket, changeDashboardDataParams, setDashboardData] =
    useWebSocket();

  useEffect(() => {
    let search = window.location.search;
    const urlParams = new URLSearchParams(search);
    console.log("urlParams ============== ", urlParams.get("psID"));

    changeDashboardDataParams({
      url: url,
      apiName: "dashboard/socket",
      params: `psID=${urlParams.get("psID") ? urlParams.get("psID") : ""}`,
    });
    setDashboardData(true);
  }, [s_psID]);

  // 第一次渲染取得所有車間資料, 並塞值給 s_searchData
  useEffect(() => {
    const call_getAllPS = async () => {
      try {
        const res = await getAllPS();
        if (res.status === true && res.data.length > 0) {
          console.log("全部車間資料 = ", res.data);
          let options = res.data.map((item) => (
            <Select.Option key={item.psID} value={item.psID}>
              {item.psNM}
            </Select.Option>
          ));
          options.push(
            <Select.Option key="index" value="">
              全部車間
            </Select.Option>
          );
          set_s_AllStation_options(options.reverse());

          // if(res.data.length > 0) {
          //   set_s_searchData({
          //     ...s_searchData,
          //     'wsID': res.data[0].wsID
          //   });
          // };
          // set_s_psID(res.data[0].psID)
          let search = window.location.search;
          const urlParams = new URLSearchParams(search);
          if (urlParams.get("psID")) {
            set_s_psID(urlParams.get("psID"));
          }
        }
      } catch (e) {
        console.log("---------- api getAllStaffPerm error ----------");
        console.log(e);
      }
    };
    call_getAllPS();
  }, []);

  useEffect(() => {
    if (dashboardDataSocket.status) {
      //message.success("看板更新成功");
      console.log(dashboardDataSocket?.data);

      setTableData(sortData(dashboardDataSocket?.data));
      // setTableData(sortData(aa));

      if (dashboardDataSocket?.data && dashboardDataSocket?.data?.length > 0) {
        // 開線數
        const openLine =
          dashboardDataSocket?.data?.filter(
            (item) => item.situF !== "W" && item.situF !== "B"
          ).length ?? 0;
        // 總線數
        const allLine = dashboardDataSocket?.data?.length ?? 0;
        // 開線率
        const openLineRate = `${
          ((openLine / allLine) * 100).toFixed(1) ?? 0
        } %`;

        set_s_boxData({ openLine, allLine, openLineRate });
      }
    } else {
      set_s_boxData({});
      //message.error("看板更新失敗");
    }
    console.log("看板資料 = ", dashboardDataSocket);
  }, [dashboardDataSocket]);

  const sortData = (data) => {
    try {
      const newData = data.map((x) => {
        return {
          ...x,
          openT: sortTime(x.openT),
          closeT: sortTime(x.closeT),
          lifeFT: sortTime(x.lifeFT),
          sliceT: sortTime(x.sliceT),
          mhC: formatFloat(x.mhC, 1),
        };
      });
      return newData;
    } catch (e) {
      console.log("------------ sortData error ------------");
      return [];
    }
  };

  function sortTime(time) {
    var newTime = moment(time).format("YYYY-MM-DD HH:mm:ss");
    if (moment(newTime).year() < 2000) {
      return null;
    } else {
      return newTime;
    }
  }

  const handleChange = (value) => {
    set_s_psID(value);
    // const urlParams = new URLSearchParams(window.location.search);
    // console.log('urlParams = ', urlParams)
    // urlParams.set('order', 'date');
    // window.location.search = urlParams;

    var url = `${window.location.pathname}?psID=${value}`;
    window.history.pushState({ url: url }, "", url);
  };

  function formatFloat(num, pos) {
    var size = Math.pow(10, pos);
    return Math.round(num * size) / size;
  }

  const tableColumns = [
    {
      title: "線別",
      dataIndex: "wlID",
      key: "wlID",
      className: "lineHeight",
      align: "center",
      //width: 100,
      width: innerWidth <= 1440 ? `${60}px` : `${70}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: text,
        };
      },
    },
    {
      title: "機台編號",
      dataIndex: "wlNM",
      key: "wlNM",
      className: "lineHeight",
      align: "center",
      //width: 100,
      width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: text,
        };
      },
    },
    {
      title: "狀態",
      dataIndex: "situF",
      key: "situF",
      className: "lineHeight",
      align: "center",
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      render: (value, recode) => {
        const colorMap = {
          W: "white",
          R: "red",
          G: "rgb(54, 230, 28)",
          Y: "yellow",
          B: "blue",
        };

        return recode.situF === "W" ? (
          showLight(colorMap[recode.situF])
        ) : (
          <Light color={recode.situF} size="17px" />
        );
        // console.log('recode.situF = ', recode.situF)
        // //if(colorMap[recode.situF]) alert(colorMap[recode.situF])
        // return showLight(colorMap[recode.situF])
      },
    },
    {
      title: "模具編號",
      dataIndex: "moldID",
      key: "moldID",
      className: "lineHeight",
      align: "center",
      //width: 100,
      width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: {
              fontSize: innerWidth <= 1440 ? "8px" : null,
              textAlign: "left",
            },
          },
          children: rowData.woN === "" ? "" : text,
        };
      },
    },
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      className: "lineHeight",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: {
              fontSize: innerWidth <= 1440 ? "8px" : null,
              textAlign: "left",
            },
          },
          children: text,
        };
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      className: "lineHeight",
      align: "center",
      render: (text, rowData, index) => {
        return {
          props: {
            style: {
              fontSize: innerWidth <= 1440 ? "8px" : null,
              textAlign: "left",
            },
          },
          children: text,
        };
      },
      // width: 160,
      width: innerWidth <= 1440 ? `${180}px` : `${160}px`,
    },
    // {
    //   title: '達成率',
    //   width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "pdtRate",
    //   key: "pdtRate",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: text
    //     };
    //   },
    // },
    // {
    //   title: '不良率',
    //   width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "ngRate",
    //   key: "ngRate",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: text
    //     };
    //   },
    // },
    {
      title: (
        <span>
          計畫
          <br />
          產量
        </span>
      ),
      // title: <span>目標<br />產量</span>,
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "allPlanqty",
      key: "allPlanqty",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              {rowData.woN === ""
                ? ""
                : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <span>
          剩餘
          <br />
          產量
        </span>
      ),
      dataIndex: "lastInC",
      key: "lastInC",
      className: "lineHeight",
      align: "center",
      render: (text, rowData, index) => {
        const color = text < 0 ? "#f0948ecf" : text < 50 ? "#f5d10396" : "";
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                backgroundColor: color,
              }}
            >
              {rowData.woN === ""
                ? ""
                : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          ),
        };
      },
      // width: 160,
      width: innerWidth <= 1440 ? `${180}px` : `${80}px`,
    },
    {
      // title: "產品週期",
      title: (
        <span>
          產品
          <br />
          週期
        </span>
      ),
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "cycletimemh",
      key: "cycletimemh",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              {rowData.woN === ""
                ? ""
                : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          ),
        };
      },
    },

    // {
    //   title: <span>良品<br />數量</span>,
    //   width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "okC",
    //   key: "okC",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: (
    //         <div style={{display: 'flex',justifyContent: 'center'}}>
    //           {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //         </div>
    //       )
    //     };
    //   },
    // },
    // {
    //   title: "前拆量",
    //   width: 100,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "d_planqty",
    //   key: "d_planqty",
    // },
    // {
    //   title: "後拆量",
    //   width: 100,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "d_inC",
    //   key: "d_inC",
    // },
    {
      // title: "模具穴數",
      title: (
        <span>
          模具
          <br />
          穴數
        </span>
      ),
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "moldcavity",
      key: "moldcavity",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              {rowData.woN === ""
                ? ""
                : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          ),
        };
      },
    },

    {
      // title: "已打模次",
      title: (
        <span>
          已打
          <br />
          模次
        </span>
      ),
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "pdtC",
      key: "pdtC",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              {rowData.woN === ""
                ? ""
                : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <span>
          投產
          <br />
          數量
        </span>
      ),
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "inC",
      key: "inC",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              {rowData.woN === ""
                ? ""
                : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          ),
        };
      },
    },
    {
      //title: "報工時間",
      title: (
        <span>
          報工
          <br />
          時間
        </span>
      ),
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "newprepareT",
      key: "newprepareT",
      render: (text, rowData, index) => {
        const data = text.split(" ");

        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children:
            innerWidth <= 1440 ? (
              <span>
                {data[0]}
                <br />
                {data[1]}
              </span>
            ) : (
              text
            ),
        };
      },
    },
    {
      //title: "在線人數",
      title: (
        <span>
          在線
          <br />
          人數
        </span>
      ),
      width: innerWidth <= 1440 ? `${50}px` : `${100}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "hdC",
      key: "hdC",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              {rowData.woN === ""
                ? ""
                : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          ),
        };
      },
    },

    // {
    //   title: <span>完工<br/>時間</span>,
    //   dataIndex: "newcloseT",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    //   className: 'lineHeight',
    //   align: 'center',
    //   key: "newcloseT",
    //   render: (text, rowData, index) => {

    //     const data = text.split(' ');

    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: innerWidth <= 1440 ? <span>{data[0]}<br />{data[1]}</span> : text
    //     };
    //   },
    // },

    {
      //title: "累計機時",
      title: (
        <span>
          累計
          <br />
          機時
        </span>
      ),
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      className: "lineHeight",
      align: "center",
      dataIndex: "emhC",
      key: "emhC",
      render: (text, rowData, index) => {
        return {
          props: {
            style: {
              textAlign: "right",
              paddingRight: "15px",
              fontSize: innerWidth <= 1440 ? "8px" : null,
            },
          },
          children: (
            <div>{rowData.woN === "" ? "" : `${text.toFixed(1)} 小時`}</div>
          ),
        };
      },
    },
    {
      title: (
        <span>
          預計
          <br />
          完工
        </span>
      ),
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "restmhc",
      key: "restmhc",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children: (
            <div style={{ display: "flex", justifyContent: "end" }}>
              {rowData.woN === ""
                ? ""
                : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                  "小時"}
            </div>
          ),
        };
      },
    },

    {
      title: (
        <span>
          生產
          <br />
          效率
        </span>
      ),
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "ePPE",
      key: "ePPE",
      render: (text, rowData, index) => {
        return {
          props: {
            style: {
              fontSize: innerWidth <= 1440 ? "8px" : null,
              textAlign: "right",
            },
          },
          children: (
            <div>{rowData.woN === "" ? "" : `${text.toFixed(1)}%`}</div>
          ),
        };
      },
    },
    // {
    //   //title: "途單歷程",
    //   title: <span>途單<br/>歷程</span>,
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "mappedLifeF",
    //   key: "mappedLifeF",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: text
    //     };
    //   },
    // },
    {
      // title: '累計達成率',
      title: (
        <span>
          累計
          <br />
          達成率
        </span>
      ),
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "allPdtRate",
      key: "allPdtRate",
      render: (text, rowData, index) => {
        return {
          props: {
            style: {
              fontSize: innerWidth <= 1440 ? "8px" : null,
              textAlign: "right",
            },
          },
          children: <div>{rowData.woN === "" ? "" : text}</div>,
        };
      },
    },
    {
      //title: "歷程時間",
      title: (
        <span>
          歷程
          <br />
          時間
        </span>
      ),
      width: innerWidth <= 1440 ? `${70}px` : `${100}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "newlifeFT",
      key: "newlifeFT",
      render: (text, rowData, index) => {
        const data = text.split(" ");

        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children:
            rowData.woN !== "" ? (
              innerWidth <= 1440 ? (
                <span>
                  {data[0]}
                  <br />
                  {data[1]}
                </span>
              ) : (
                text
              )
            ) : null,
        };
      },
      // render: (text, rowData, index) => {
      //   const date = rowData.newlifeFT.split(' ');

      //   return <span>{date[0]}<br />{date[1]}</span>
      // }
    },
    {
      //title: "歷程時間",
      title: (
        <span>
          更新
          <br />
          時間
        </span>
      ),
      width: innerWidth <= 1440 ? `${70}px` : `${100}px`,
      align: "center",
      className: "lineHeight",
      dataIndex: "newsliceT",
      key: "newsliceT",
      render: (text, rowData, index) => {
        const data = text.split(" ");

        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? "8px" : null },
          },
          children:
            innerWidth <= 1440 ? (
              <span>
                {data[0]}
                <br />
                {data[1]}
              </span>
            ) : (
              text
            ),
        };
      },
      // render: (text, rowData, index) => {
      //   const date = rowData.newlifeFT.split(' ');

      //   return <span>{date[0]}<br />{date[1]}</span>
      // }
    },
    // {
    //   title: <span>切片<br/>時間</span>,
    //   width: 100,
    //   dataIndex: "sliceT",
    //   key: "sliceT",
    // },
  ];
  const getDate = (date) => {
    const month = "" + (date.getMonth() + 1);
    const day = "" + date.getDate();
    const year = date.getFullYear();
    console.log(month, day);
  };
  const showLight = (color) => {
    console.log(color);
    const style = {
      width: "20px",
      height: "20px",
      backgroundColor: color,
      display: "inline-block",
      borderRadius: "50%",
      border: "0.1px solid #E0E0E0",
      verticalAlign: "middle",
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={style} />
      </div>
    );
  };

  return (
    <>
      {/* <MyMainColorH1>數位生產看板</MyMainColorH1> */}
      <Title title="數位生產看板">
        <Header>
          <DashBox
            header="開線率"
            value={
              Object.keys(s_boxData).length > 0 ? s_boxData.openLineRate : ""
            }
          ></DashBox>
          <DashBox
            header="開線數"
            value={Object.keys(s_boxData).length > 0 ? s_boxData.openLine : ""}
          />
          <DashBox
            header="總線數"
            value={Object.keys(s_boxData).length > 0 ? s_boxData.allLine : ""}
          ></DashBox>
          <Select
            style={{ width: "150px" }}
            size="large"
            value={s_psID}
            disabled // 2022-01-14 因為要 demo 所以先鎖住
            onChange={(value) => handleChange(value)}
          >
            {s_AllStation_options}
          </Select>
        </Header>
      </Title>

      <Header1>
        <DashBox
          header="開線率"
          value={
            Object.keys(s_boxData).length > 0 ? s_boxData.openLineRate : ""
          }
        ></DashBox>
        <DashBox
          header="開線數"
          value={Object.keys(s_boxData).length > 0 ? s_boxData.openLine : ""}
        />
        <DashBox
          header="總線數"
          value={Object.keys(s_boxData).length > 0 ? s_boxData.allLine : ""}
        ></DashBox>
        <Select
          style={{ width: "150px" }}
          size="large"
          value={s_psID}
          disabled // 2022-01-14 因為要 demo 所以先鎖住
          onChange={(value) => handleChange(value)}
        >
          {s_AllStation_options}
        </Select>
      </Header1>
      {/* <Row justify="center" align="middle">
        <Col span={24}> */}
      <CustomTable
        scroll={{ x: "max-content", y: window.innerHeight - 60 - 45 - 180 }}
        pagination={false}
        // bordered={true}
        size={innerWidth <= 1440 ? "14px" : null}
        columns={tableColumns}
        dataSource={tableData.map((x, index) => {
          return { ...x, key: index };
        })}
      />
      {/* </Col>
      </Row> */}
      {s_showModal ? (
        <CustomModal
          width="30%"
          title="警示設定"
          visible={s_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
        >
          <ModalContainer set_s_showModal={set_s_showModal} />
        </CustomModal>
      ) : null}
    </>
  );
}

export default DashBoard;
