import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import { getAllStation, getWpcRun } from '../../services/api';
import { MySubtitleH4, fontSize } from '../../styled/publicTag';
import { Select, message, Input } from 'antd';
import Title from '../../components/Custom/Title';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomTabs from '../../components/antdCustom/CustomTabs';

import TotalData from './TotalData';

const WlText = styled.div`
  font-size: ${fontSize.h4};
  color: ${({ theme }) => theme.Color3};
`

const WorkWaybill = () => {

  const innerWidth = window.innerWidth;

  const [s_AllStation_options, set_s_AllStation_options] = useState([]); // 所有工站

  const [s_tableData, set_s_tableData] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [s_count_tableData, set_s_count_tableData] = useState([]);  // 總計的資料

  const [s_AllStation, set_s_AllStation] = useState([]); // 所有工站

  const [s_searchData, set_s_searchData] = useState({
    'PN': '',          // 料號
    'woN': '',         // 工單號
    'wsID': '',
    'tab': 'aps',
  });

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10,
    page: 1
  });

  const TabsData = [
    {
      'key': 'aps',
      'tabName': '生管發佈'
    },
    {
      'key': '0',
      'tabName': '產線發佈'
    },
    {
      'key': '1',
      'tabName': '登錄'
    },
    {
      'key': 'B',
      'tabName': '上模中'
    },
    {
      'key': '2',
      'tabName': '生產中'
    },
    {
      'key': '7',
      'tabName': '報完工'
    },
    {
      'key': 'L',
      'tabName': '物控'
    },
    {
      'key': 'P',
      'tabName': '質控'
    },
    {
      'key': 'G',
      'tabName': '過站'
    }
  ]

  // 切換搜尋項目(工站、tab)
  const handleChange = (type, value) => {
    const tmp = { ...s_searchData, [type]: value };
    console.log('tmp = ', tmp)
    set_s_searchData(tmp);
  }

  // 第一次渲染取得所有工站資料, 並塞值給 s_searchData
  useEffect(() => {
    const call_getAllStation = async () => {
      try {
        // const res = {
        //   "status": 404,
        //   "msg": "查無途單資料",
        //   "data": null
        // }
        const res = await getAllStation();
        if(res.status === true && res.data.length > 0) {
          console.log('全部工站資料 = ', res.data);
          let options = res.data.map(item => <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>);
          //options.splice(0, 0, <Select.Option key='index1' value=''>全部工站</Select.Option>);
          set_s_AllStation_options(options);

          //全部工站資料(Object)
          let StationMap = res.data.reduce((prev, curr) => {
            const tmpObj = Object.assign( prev, {[curr.wsID]: curr.wsNM});
            return tmpObj;
          }, {});

          set_s_AllStation(StationMap);
          set_s_searchData({
            ...s_searchData,
            'wsID': res.data[0].wsID,
          })
          //console.log('StationMap = ', StationMap)
        }
      } catch (e) {
        console.log('---------- api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
    call_getAllStation();
  }, []);

  // 取得工站途單資料
  useEffect(() => {
    if(s_searchData.wsID === '') {
      set_s_tableData([]);
      return;
    };

    const call_getWpcRun = async () => {
      try {

        setTableLoading(true);
        
        const res = await getWpcRun(s_searchData);
        console.log('s_searchData = ', s_searchData)
      
        if(res.status === true && res.data.length > 0) {
          console.log('工站途單資料 = ', res.data)
          
          set_s_tableData(res.data);
          
          let countObj = {
            'woN': 0,
            'planQty': 0,
            'd_planQty': 0,
            'inC': 0,
            'd_inC': 0,
            'ngC': 0,
            'okC': 0,
            'bal': 0
          };

          for(let i=0; i<res.data.length; i++) {
            const tmpObj = res.data[i];
            countObj.planQty = countObj.planQty + tmpObj.planQty;
            countObj.d_planQty = countObj.d_planQty + tmpObj.d_planQty;
            countObj.inC = countObj.inC + tmpObj.inC;
            countObj.d_inC = countObj.d_inC + tmpObj.d_inC;
            countObj.ngC = countObj.ngC + tmpObj.ngC;
            countObj.okC = countObj.okC + tmpObj.okC;
            countObj.bal = countObj.bal + tmpObj.bal;
          }

          countObj.woN = res.data.length;

          set_s_count_tableData([countObj]);
        
        } else {
          set_s_tableData([]);
          set_s_count_tableData([]);
        }

        res.status === true ? message.success(res.msg) : message.error(res.msg)
        
        setTableLoading(false);
      } catch (e) {
        setTableLoading(false);

        set_s_tableData([]);
        // set_s_count_tableData([]);
      }
      
    }

    const timeoutID = setTimeout( () => { 
      call_getWpcRun();
    }, 500);
    return () => {
      clearTimeout(timeoutID);
    }

    // call_getWpcRun();

  }, [s_searchData]);

  const tableColumns = [
    {
      title: '歷程時間',
      dataIndex: 'newlifeFT',
      key: 'newlifeFT',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    {
      title: '線別',
      dataIndex: 'wlNM',
      key: 'wlNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '工單',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '單別',
      dataIndex: 'woType',
      key: 'woType',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '途程號',
      dataIndex: 'wpcSN',
      key: 'wpcSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '計畫產量',
      dataIndex: 'planQty',
      key: 'planQty',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'end'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: '前拆數量',
      dataIndex: 'd_planQty',
      key: 'd_planQty',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: '投產數量',
      dataIndex: 'inC',
      key: 'inC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: '後拆數量',
      dataIndex: 'd_inC',
      key: 'd_inC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: '不良數量',
      dataIndex: 'ngC',
      key: 'ngC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: '產出數量',
      dataIndex: 'okC',
      key: 'inC',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: '帳差平衡',
      dataIndex: 'bal',
      key: 'bal',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render:(text)=><div className='flex-end'>{text}</div>
    },
    {
      title: '途程組',
      dataIndex: 'wpcSet',
      key: 'wpcSet',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`
    },
    {
      title: '插工碼',
      dataIndex: 'addSN',
      key: 'addSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`
    },
    {
      title: '前拆碼',
      dataIndex: 'prefix',
      key: 'prefix',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`
    },
    {
      title: '後拆碼',
      dataIndex: 'suffix',
      key: 'suffix',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`
    },
    {
      title: '過站碼',
      dataIndex: 'sqc',
      key: 'sqc',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${90}px`
    },
  ];

  const totalColumns = [
    {
      title: '工單總數',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: '總計畫產量',
      dataIndex: 'planQty',
      key: 'planQty',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '總前拆數量',
      dataIndex: 'd_planQty',
      key: 'd_planQty',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '總投產數量',
      dataIndex: 'inC',
      key: 'inC',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '總後拆數量',
      dataIndex: 'd_inC',
      key: 'd_inC',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '總不良數量',
      dataIndex: 'ngC',
      key: 'ngC',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '總產出數量',
      dataIndex: 'okC',
      key: 'okC',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '總帳差平衡',
      dataIndex: 'bal',
      key: 'bal',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
  ]

  return (
    <>
      <Title title='工站途單查詢'>
        <div style={{display: 'flex', alignItems: 'center'}}>
        <Input
            style={{ width: '180px', marginRight: '15px' }}
            size='large'
            placeholder='請輸入工單號'
            value={s_searchData.woN}
            onChange={e => handleChange('woN', e.target.value)}
          />
          <Input
            style={{ width: '180px', marginRight: '15px' }}
            size='large'
            placeholder='請輸入料號'
            value={s_searchData.PN}
            onChange={e => handleChange('PN', e.target.value)}
          />
          <Select 
            style={{marginRight: '15px', width: '150px'}} 
            size='large'
            value={s_searchData.wsID}
            onChange={value => handleChange('wsID', value)}
          >
            {s_AllStation_options}
          </Select>
        </div>
      </Title>

      {/* 用來顯示目前的工站 */}
      <div style={{marginTop: '15px', marginBottom: '15px'}}>
        {Object.keys(s_AllStation).length > 0 && s_searchData.wsID ? (
          <WlText>
            {`目前查詢工站： ${s_searchData.wsID} - ${s_AllStation[s_searchData.wsID]}`}
          </WlText>
        ) : null} 
      </div>

      {/* 切換 lifeF */}
      <CustomTabs 
        onChange={activeKey => handleChange('tab', activeKey)}
        activeKey={s_searchData.tab}
        TabsData={TabsData}
      />

      {/* 資料總計 */}
      <TotalData loading={tableLoading} s_count_tableData={s_count_tableData} />
      {/* <div style={{ marginBottom: '20px' }}>
        <CustomTable 
          style={{width: '70%'}}
          columns={totalColumns}
          bordered
          dataSource={s_count_tableData && s_count_tableData.length > 0 && s_count_tableData.map((item, index) => {
            return  { ...item, key: index }
          })}
          pagination={false}

        />
      </div> */}

      <CustomTable 
        loading={tableLoading}
        scroll={{ x: "max-content",y: window.innerHeight - 60 - 45 - 220 - 90 - 100 }}
        columns={tableColumns}
        dataSource={s_tableData && s_tableData.length > 0 && s_tableData.map((item, index) => {
          return  { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              page: current,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      
    </>
  )
};

export default WorkWaybill;