import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { MySubtitleH4, fontSize } from '../../styled/publicTag';

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const Box = styled.div`
  .Header {
    background-color: ${({theme}) => theme.Color4};
    border: 1px solid #C4C4C4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: ${fontSize.text};
    padding: 5px 25px 5px 25px;

    // ipad air
    @media screen and (max-width: 1180px) {
      text-align: center;
      padding: 5px 10px 5px 10px;
    }

    // ipad air
    @media screen and (max-width: 820px) {
      text-align: center;
      font-size: 17px;
      padding: 5px;
    }
  }

  .Value {
    border-left: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    font-size: ${fontSize.text};
    padding: 5px 25px 5px 25px;
  }
`

const TotalData = ({loading, s_count_tableData}) => {

  console.log('s_count_tableData = ', s_count_tableData)

  const UIdata = s_count_tableData.length > 0 ? (
    s_count_tableData.map((item, index) => {
      return (
        <Container key={index}>
          <Box>
            <div className='Header'>工單總數</div>
            <div className='Value'>
              {loading ? <Spin size="small" /> : isNaN(item.woN) ? 0 : item.woN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Box>
          {/* <Box>
            <div className='Header'>總計畫產量</div>
            <div className='Value'>
              {loading ? <Spin size="small" /> : isNaN(item.planQty) ? 0 : item.planQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Box> */}
          <Box>
            <div className='Header'>總前拆數量</div>
            <div className='Value'>
              {loading ? <Spin size="small" /> : isNaN(item.d_planQty) ? 0 : item.d_planQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Box>
          <Box>
            <div className='Header'>總投產數量</div>
            <div className='Value'>
              {loading ? <Spin size="small" /> : isNaN(item.inC) ? 0 : item.inC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Box>
          <Box>
            <div className='Header'>總後拆數量</div>
            <div className='Value'>
              {loading ? <Spin size="small" /> : isNaN(item.d_inC) ? 0 : item.d_inC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Box>
          <Box>
            <div className='Header'>總不良數量</div>
            <div className='Value'>
              {loading ? <Spin size="small" /> : isNaN(item.ngC) ? 0 : item.ngC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Box>
          <Box>
            <div className='Header'>總產出數量</div>
            <div className='Value'>
              {loading ? <Spin size="small" /> : isNaN(item.okC) ? 0 : item.okC.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Box>
          <Box>
            <div className='Header'>總帳差平衡</div>
            <div className='Value'>
              {loading ? <Spin size="small" /> : isNaN(item.bal) ? 0 : item.bal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </Box>
        </Container>
      )
    })
  ) : null;

  return (
    <Wrapper>
      {UIdata}
    </Wrapper>
  )
};

export default TotalData;