import React from "react";
//import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
//import { CSSTransition, TransitionGroup } from "react-transition-group";
import Users from "../pages/Users";
import SystemLogin from "../pages/SystemLogin";
import DashBoard from "../pages/DashBoard";
import DashBoardCard from "../pages/DashBoardCard";
import AbcBoard from "../pages/AbcBoard";
import WosPermissions from "../pages/WosPermissions";
import BasicProductInfo from "../pages/BasicProductInfo";
import PSdata from "../pages/PSdata";
import Station from "../pages/Station";
import Line from "../pages/Line";
import Mould from "../pages/Mould";
import ProductWpc from "../pages/ProductWpc";
import OverView from "../pages/OverView";
import OverViewLines from "../pages/OverViewLines";
import WorkStations from "../pages/WorkStations";
import WorkResume from "../pages/WorkResume";
import WorkInProduction from "../pages/WorkInProduction";
import WorkPassStations from "../pages/WorkPassStations";
import WorkWaybill from "../pages/WorkWaybill";
import ApsManagement from "../pages/ApsManagement";
import Department from "../pages/Department";
import JobTitle from "../pages/JobTitle";
import ProductionInfo from "../pages/ProductionInfo";
import BadProject from "../pages/BadProject";
import GoodProject from "../pages/GoodProject";
import StopProject from "../pages/StopProject";
import WareHouse from "../pages/WareHouse";
import TestSVG from "../pages/TestSVG";
import FunctionProject from "../pages/FunctionProject";
import Companys from "../pages/Companys";
import OemInfo from "../pages/OemInfo";
import Basic from "../pages/Basic";
import MoldModify from "../pages/MoldModify";
//WMS
import PlainIn from "../pages/WMS/PlanIn";

const Routes = () => {
  let location = useLocation();

  const r_userState = useSelector((allReducers) => allReducers.userState);
  //console.log('r_userState = ', r_userState)

  return (
    <>
      {Object.keys(r_userState).length > 0 ? (
        <Switch location={location}>
          {r_userState.setBDM === "1" ? (
            <Route path="/BasicProductInfo" component={BasicProductInfo} />
          ) : null}
          {r_userState.setBDM === "1" ? (
            <Route path="/ProductWpc" component={ProductWpc} />
          ) : null}
          {r_userState.setBDM === "1" ? (
            <Route path="/Mould" component={Mould} />
          ) : null}
          {r_userState.pdsID === "superman" ? (
            <Route path="/PSdata" component={PSdata} />
          ) : null}
          {r_userState.pdsID === "superman" ? (
            <Route path="/Station" component={Station} />
          ) : null}
          {r_userState.pdsID === "superman" ? (
            <Route path="/Line" component={Line} />
          ) : null}

          {r_userState.setSDM === "1" ? (
            <Route path="/Users" component={Users} />
          ) : null}
          {r_userState.setSDM === "1" ? (
            <Route path="/WosPermissions" component={WosPermissions} />
          ) : null}
          {r_userState.setSDM === "1" ? (
            <Route path="/SystemLogin" component={SystemLogin} />
          ) : null}
          {r_userState.setSDM === "1" ? (
            <Route path="/BadProject" component={BadProject} />
          ) : null}
          {r_userState.setSDM === "1" ? (
            <Route path="/GoodProject" component={GoodProject} />
          ) : null}
          {r_userState.setSDM === "1" ? (
            <Route path="/StopProject" component={StopProject} />
          ) : null}
          {r_userState.setSDM === "1" ? (
            <Route path="/FunctionProject" component={FunctionProject} />
          ) : null}

          {r_userState.setSDM === "1" ? (
            <Route path="/Companys" component={Companys} />
          ) : null}

          {r_userState.setOPS === "1" ? (
            <Route path="/MoldModify" component={MoldModify} />
          ) : null}

          <Route path="/WorkStations" component={WorkStations} />
          <Route path="/WareHouse" component={WareHouse} />
          <Route path="/WorkResume" component={WorkResume} />
          <Route path="/WorkInProduction" component={WorkInProduction} />
          <Route path="/dashboard" component={DashBoard} />
          <Route path="/dashboardCard" component={DashBoardCard} />
          <Route path="/abcboard" component={AbcBoard} />
          <Route path="/WorkResume" component={WorkResume} />
          <Route path="/WorkPassStations" component={WorkPassStations} />
          <Route path="/abcboard" component={AbcBoard} />
          <Route path="/OverView" component={OverView} />
          <Route path="/OverViewLines" component={OverViewLines} />
          <Route path="/WorkWaybill" component={WorkWaybill} />
          <Route path="/ApsManagement" component={ApsManagement} />
          {/* <Route path="/Shift" component={Shift} /> */}
          <Route path="/ProductionInfo" component={ProductionInfo} />
          <Route path="/OemInfo" component={OemInfo} />
          <Route path="/Basic" component={Basic} />
          <Route path="/PlainIn" component={PlainIn} />

          {r_userState.pdsID === "superman" ? (
            <Route path="/Department" component={Department} />
          ) : null}

          {r_userState.pdsID === "superman" ? (
            <Route path="/JobTitle" component={JobTitle} />
          ) : null}

          <Route path="/TestSVG" component={TestSVG} />

          {/* <Route path="/LineDetails" component={LineDetails} /> */}
          <Redirect exact from="*" to="/dashboard" />
        </Switch>
      ) : null}
    </>
  );
};
export default Routes;
