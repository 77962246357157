import React from 'react';
import styled from 'styled-components';

import { fontSize } from '../../styled/publicTag';

const Wrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  display: flex;
  /* padding: 1px; */

  width: 150px;
  height: 40px;
  
  margin-right: 15px;

  // ipad mini
  @media screen and (max-width: 768px) {
    width: 120px;
  }
  
  // ipad air
  @media screen and (min-width: 820px) {
    width: 130px
  }

  .Header {
    width: 50%;
    background-color: ${({theme}) => theme.Color4};
    //padding: 5px;
    line-height: 40px;
    text-align: center;
    font-size: ${fontSize.text};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: ${({ theme }) => theme.text1};

    // ipad mini
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }

    // ipad air
    @media screen and (max-width: 820px) {
      font-size: 17px;
    }
  }

  .Value {
    width: 50%;
    font-size: ${fontSize.text};
    color: ${({theme}) => theme.text1};
    background-color: ${({theme}) => theme.dashBox};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 40px;
    text-align: center;

    // ipad air
    @media screen and (min-width: 820px) {
      font-size: 17px;
    }
  }
`

const DashBox = ({header, value}) => {

  return (
    <Wrapper>
      <div className='Header'>
        {header}
      </div>
      <div className='Value'>
        {value}
      </div>
    </Wrapper>
  )
};

export default DashBox;