import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { url } from "../../services/handlerAPI";
import { getAllStation } from '../../services/api';
import moment from 'moment';

import { Select, DatePicker } from 'antd';

import { fontSize } from '../../styled/publicTag';

import useWebSocket from "../../utility/useWebSocket_new";

import LineChart from './LineChart';
import ProgressChart from './ProgressChart';
import BarChart from './BarChart';
import TimeChart from './TimeChart';
import Title from '../../components/Custom/Title';

const Wrapper = styled.div`
  padding: 0px 20px 0px 25px;
  background-color: ${({ theme }) => theme.mainBg};
  height: calc(100vh - 120px);
  //background-color: green;

  // 折線圖跟水波圖的區域
  .ChartContainer {
    display: flex;
    justify-content: space-between;
    height: 350px;
  }

  // 稼動
  .FacetChart {
    //height: calc(100% - 350px - 100px);
    height: calc(100% - 400px);
    margin-top: 30px;
    //border: 1px solid red;
    //margin-bottom: 20px;
    //background-color: red
    /* z-index: 99; */
    //overflow: auto; 
  }

  // 稼動率的區塊
  .FacetChartContainer {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    height: 50px;
    //margin-top: 20px;
    display: flex;
    //background-color: pink
  }

  .Container {
    height: calc(100% - 70px);
    overflow: auto;
    
  }

  .FacetChartContainer1 {
    //box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    //height: 50px;
    /* margin-top: 20px; */
    display: flex;
    padding: 5px;
    box-shadow: 5px 1px 1px rgb(0 0 0 / 20%);
  }

  // 稼動率的 header title
  .colHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fontSize.p};
    color: #3089c7;
    //padding-top: 10px;
    //padding-bottom: 10px;
    @media screen and (max-width: 820px) {
        font-size: 17px;
    }
  }

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // 線別的按鈕
  .wsBtn {
    color: rgba(0, 0, 0, 0.87);
    //width: 60px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0;
    padding: 6px 16px;
  }
`

const OverView = () => {

  const [s_AllStation_options, set_s_AllStation_options] = useState([]); // 所有工站選單
  const [s_AllStation, set_s_AllStation] = useState([]); // 所有工站選單

  const [s_act, set_s_act] = useState([]);
  const [s_board, set_s_board] = useState([]);
  
  const [s_searchData, set_s_searchData] = useState({
    'wsID': '',
    'date': moment(new Date()).format('YYYY-MM-DD'),
  });

  const [DataSocket, changeDataParams, setData] = useWebSocket();

  // 第一次渲染取得所有工站資料, 並塞值給 s_searchData
  useEffect(() => {
    const call_getAllStation = async () => {
      try {
        const res = await getAllStation();
        if(res.status === true && res.data.length > 0) {
          //console.log('全部工站資料 = ', res.data);
          let options = res.data.map(item => <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>);
          set_s_AllStation_options(options);
          set_s_AllStation(res.data);

          if(res.data.length > 0) {
            set_s_searchData({
              ...s_searchData,
              'wsID': res.data[0].wsID
            });
          };
          
        }
      } catch (e) {
        console.log('---------- api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
    call_getAllStation();
  }, []);

  useEffect(() => {
    
    changeDataParams({
      url: url,
      apiName: "act/socket",
      params: `wsID=${s_searchData.wsID}&date=${s_searchData.date}`
    });
    setData(true);
  }, [s_searchData]);

  useEffect(() => {
    //console.log('DataSocket.data = ', DataSocket.data)
    if(DataSocket.status === true) {
      console.log('DataSocket.data = ', DataSocket.data)
      const aa = [
        {
            "wlID": "L01",
            "morningData": [
                {
                    "woN": "344",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T08:46:28+08:00",
                    "keeptime": 3126,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 08:46",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                // {
                //     "woN": "344",
                //     "situF": "G",
                //     "startTime": "2021-11-03T08:46:28+08:00",
                //     "endTime": "2021-11-03T09:27:12.547983359+08:00",
                //     "keeptime": 2444,
                //     "newstartTime": "11-03 08:46",
                //     "newendTime": "11-03 09:27",
                //     "shiftstartTime": "11-03 08:00",
                //     "shiftendTime": "11-03 20:00"
                // }
            ],
            "nightData": null,
            "MorningACT": 6,
            "NightACT": 0,
            "AllACT": 6
        },
        {
            "wlID": "L02",
            "morningData": [
                {
                    "woN": "Aw001",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T08:38:52+08:00",
                    "keeptime": 2670,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 08:38",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw001",
                    "situF": "G",
                    "startTime": "2021-11-03T08:38:52+08:00",
                    "endTime": "2021-11-03T09:27:12.548460118+08:00",
                    "keeptime": 2900,
                    "newstartTime": "11-03 08:38",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 7,
            "NightACT": 0,
            "AllACT": 7
        },
        {
            "wlID": "L03",
            "morningData": [
                {
                    "woN": "Aw003",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:07+08:00",
                    "endTime": "2021-11-03T08:41:53+08:00",
                    "keeptime": 2866,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 08:41",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw003",
                    "situF": "Y",
                    "startTime": "2021-11-03T08:41:53+08:00",
                    "endTime": "2021-11-03T09:18:57+08:00",
                    "keeptime": 2224,
                    "newstartTime": "11-03 08:41",
                    "newendTime": "11-03 09:18",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw003",
                    "situF": "R",
                    "startTime": "2021-11-03T09:18:57+08:00",
                    "endTime": "2021-11-03T09:20:02+08:00",
                    "keeptime": 65,
                    "newstartTime": "11-03 09:18",
                    "newendTime": "11-03 09:20",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw003",
                    "situF": "Y",
                    "startTime": "2021-11-03T09:20:02+08:00",
                    "endTime": "2021-11-03T09:25:57+08:00",
                    "keeptime": 355,
                    "newstartTime": "11-03 09:20",
                    "newendTime": "11-03 09:25",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw003",
                    "situF": "R",
                    "startTime": "2021-11-03T09:25:57+08:00",
                    "endTime": "2021-11-03T09:27:12.548901685+08:00",
                    "keeptime": 75,
                    "newstartTime": "11-03 09:25",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 6,
            "NightACT": 0,
            "AllACT": 6
        },
        {
            "wlID": "L04",
            "morningData": [
                {
                    "woN": "Aw004",
                    "situF": "R",
                    "startTime": "2021-11-03T07:53:57+08:00",
                    "endTime": "2021-11-03T08:47:08+08:00",
                    "keeptime": 3191,
                    "newstartTime": "11-03 07:53",
                    "newendTime": "11-03 08:47",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw004",
                    "situF": "G",
                    "startTime": "2021-11-03T08:47:08+08:00",
                    "endTime": "2021-11-03T09:27:12.549328223+08:00",
                    "keeptime": 2404,
                    "newstartTime": "11-03 08:47",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 6,
            "NightACT": 0,
            "AllACT": 6
        },
        {
            "wlID": "L05",
            "morningData": [
                {
                    "woN": "Aw005",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T08:49:44+08:00",
                    "keeptime": 3322,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 08:49",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw005",
                    "situF": "G",
                    "startTime": "2021-11-03T08:49:44+08:00",
                    "endTime": "2021-11-03T09:27:12.549794748+08:00",
                    "keeptime": 2248,
                    "newstartTime": "11-03 08:49",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 5,
            "NightACT": 0,
            "AllACT": 5
        },
        {
            "wlID": "L06",
            "morningData": [
                {
                    "woN": "34455",
                    "situF": "W",
                    "startTime": "2021-11-02T03:49:24+08:00",
                    "endTime": "2021-11-03T09:27:12.550259896+08:00",
                    "keeptime": 106668,
                    "newstartTime": "11-02 03:49",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L07",
            "morningData": [
                {
                    "woN": "Aw007",
                    "situF": "R",
                    "startTime": "2021-11-03T07:53:57+08:00",
                    "endTime": "2021-11-03T08:54:44+08:00",
                    "keeptime": 3647,
                    "newstartTime": "11-03 07:53",
                    "newendTime": "11-03 08:54",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw007",
                    "situF": "Y",
                    "startTime": "2021-11-03T08:54:44+08:00",
                    "endTime": "2021-11-03T09:27:12.550732407+08:00",
                    "keeptime": 1948,
                    "newstartTime": "11-03 08:54",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 5,
            "NightACT": 0,
            "AllACT": 5
        },
        {
            "wlID": "L08",
            "morningData": [
                {
                    "woN": "Aw008",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T08:59:30+08:00",
                    "keeptime": 3908,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 08:59",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw008",
                    "situF": "G",
                    "startTime": "2021-11-03T08:59:30+08:00",
                    "endTime": "2021-11-03T09:27:12.551183954+08:00",
                    "keeptime": 1662,
                    "newstartTime": "11-03 08:59",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 4,
            "NightACT": 0,
            "AllACT": 4
        },
        {
            "wlID": "L09",
            "morningData": [
                {
                    "woN": "Aw009",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T08:59:30+08:00",
                    "keeptime": 3908,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 08:59",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw009",
                    "situF": "G",
                    "startTime": "2021-11-03T08:59:30+08:00",
                    "endTime": "2021-11-03T09:27:12.551671643+08:00",
                    "keeptime": 1662,
                    "newstartTime": "11-03 08:59",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 4,
            "NightACT": 0,
            "AllACT": 4
        },
        {
            "wlID": "L10",
            "morningData": [
                {
                    "woN": "Aw010",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T09:00:35+08:00",
                    "keeptime": 3973,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 09:00",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw010",
                    "situF": "G",
                    "startTime": "2021-11-03T09:00:35+08:00",
                    "endTime": "2021-11-03T09:27:12.552130156+08:00",
                    "keeptime": 1597,
                    "newstartTime": "11-03 09:00",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 4,
            "NightACT": 0,
            "AllACT": 4
        },
        {
            "wlID": "L11",
            "morningData": [
                {
                    "woN": "Aw011",
                    "situF": "R",
                    "startTime": "2021-11-02T08:59:04+08:00",
                    "endTime": "2021-11-03T09:27:12.552594369+08:00",
                    "keeptime": 88088,
                    "newstartTime": "11-02 08:59",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L12",
            "morningData": [
                {
                    "woN": "Aw012",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T09:04:55+08:00",
                    "keeptime": 4233,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 09:04",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw012",
                    "situF": "G",
                    "startTime": "2021-11-03T09:04:55+08:00",
                    "endTime": "2021-11-03T09:27:12.553086005+08:00",
                    "keeptime": 1337,
                    "newstartTime": "11-03 09:04",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 3,
            "NightACT": 0,
            "AllACT": 3
        },
        {
            "wlID": "L13",
            "morningData": [
                {
                    "woN": "Aw013",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:32+08:00",
                    "endTime": "2021-11-03T09:08:21+08:00",
                    "keeptime": 4429,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 09:08",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw013",
                    "situF": "Y",
                    "startTime": "2021-11-03T09:08:21+08:00",
                    "endTime": "2021-11-03T09:27:12.553630246+08:00",
                    "keeptime": 1131,
                    "newstartTime": "11-03 09:08",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 3,
            "NightACT": 0,
            "AllACT": 3
        },
        {
            "wlID": "L14",
            "morningData": [
                {
                    "woN": "Aw014",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T09:02:45+08:00",
                    "keeptime": 4103,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 09:02",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw014",
                    "situF": "G",
                    "startTime": "2021-11-03T09:02:45+08:00",
                    "endTime": "2021-11-03T09:27:12.554101107+08:00",
                    "keeptime": 1467,
                    "newstartTime": "11-03 09:02",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 3,
            "NightACT": 0,
            "AllACT": 3
        },
        {
            "wlID": "L15",
            "morningData": [
                {
                    "woN": "Aw015",
                    "situF": "R",
                    "startTime": "2021-11-03T07:54:22+08:00",
                    "endTime": "2021-11-03T08:59:30+08:00",
                    "keeptime": 3908,
                    "newstartTime": "11-03 07:54",
                    "newendTime": "11-03 08:59",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw015",
                    "situF": "G",
                    "startTime": "2021-11-03T08:59:30+08:00",
                    "endTime": "2021-11-03T09:27:12.554563978+08:00",
                    "keeptime": 1662,
                    "newstartTime": "11-03 08:59",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 4,
            "NightACT": 0,
            "AllACT": 4
        },
        {
            "wlID": "L16",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L17",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L18",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L19",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L20",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L21",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L22",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L23",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L24",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L25",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L26",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L27",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L28",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L29",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L30",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L31",
            "morningData": [
                {
                    "woN": "Aw031",
                    "situF": "R",
                    "startTime": "2021-11-02T08:33:16+08:00",
                    "endTime": "2021-11-03T09:27:12.5593218+08:00",
                    "keeptime": 89636,
                    "newstartTime": "11-02 08:33",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L32",
            "morningData": null,
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L33",
            "morningData": [
                {
                    "woN": "Aw033",
                    "situF": "R",
                    "startTime": "2021-11-03T07:51:07+08:00",
                    "endTime": "2021-11-03T09:26:38+08:00",
                    "keeptime": 5731,
                    "newstartTime": "11-03 07:51",
                    "newendTime": "11-03 09:26",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw033",
                    "situF": "Y",
                    "startTime": "2021-11-03T09:26:38+08:00",
                    "endTime": "2021-11-03T09:27:12.560054864+08:00",
                    "keeptime": 34,
                    "newstartTime": "11-03 09:26",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L34",
            "morningData": [
                {
                    "woN": "Aw034",
                    "situF": "R",
                    "startTime": "2021-11-03T07:51:07+08:00",
                    "endTime": "2021-11-03T09:27:12.560550067+08:00",
                    "keeptime": 5765,
                    "newstartTime": "11-03 07:51",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L35",
            "morningData": [
                {
                    "woN": "Aw035",
                    "situF": "R",
                    "startTime": "2021-11-03T07:51:07+08:00",
                    "endTime": "2021-11-03T09:17:57+08:00",
                    "keeptime": 5210,
                    "newstartTime": "11-03 07:51",
                    "newendTime": "11-03 09:17",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw035",
                    "situF": "G",
                    "startTime": "2021-11-03T09:17:57+08:00",
                    "endTime": "2021-11-03T09:27:12.561027576+08:00",
                    "keeptime": 555,
                    "newstartTime": "11-03 09:17",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 1,
            "NightACT": 0,
            "AllACT": 1
        },
        {
            "wlID": "L36",
            "morningData": [
                {
                    "woN": "Aw036",
                    "situF": "R",
                    "startTime": "2021-11-03T07:51:07+08:00",
                    "endTime": "2021-11-03T09:25:32+08:00",
                    "keeptime": 5665,
                    "newstartTime": "11-03 07:51",
                    "newendTime": "11-03 09:25",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw036",
                    "situF": "G",
                    "startTime": "2021-11-03T09:25:32+08:00",
                    "endTime": "2021-11-03T09:27:12.561602326+08:00",
                    "keeptime": 100,
                    "newstartTime": "11-03 09:25",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L37",
            "morningData": [
                {
                    "woN": "Aw037",
                    "situF": "R",
                    "startTime": "2021-11-03T07:51:07+08:00",
                    "endTime": "2021-11-03T09:08:11+08:00",
                    "keeptime": 4624,
                    "newstartTime": "11-03 07:51",
                    "newendTime": "11-03 09:08",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw037",
                    "situF": "G",
                    "startTime": "2021-11-03T09:08:11+08:00",
                    "endTime": "2021-11-03T09:27:12.562067921+08:00",
                    "keeptime": 1141,
                    "newstartTime": "11-03 09:08",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 3,
            "NightACT": 0,
            "AllACT": 3
        },
        {
            "wlID": "L38",
            "morningData": [
                {
                    "woN": "Aw038",
                    "situF": "W",
                    "startTime": "2021-11-02T09:33:26+08:00",
                    "endTime": "2021-11-03T09:27:12.562535111+08:00",
                    "keeptime": 86026,
                    "newstartTime": "11-02 09:33",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 0,
            "NightACT": 0,
            "AllACT": 0
        },
        {
            "wlID": "L39",
            "morningData": [
                {
                    "woN": "Aw039",
                    "situF": "R",
                    "startTime": "2021-11-03T07:50:12+08:00",
                    "endTime": "2021-11-03T08:56:24+08:00",
                    "keeptime": 3972,
                    "newstartTime": "11-03 07:50",
                    "newendTime": "11-03 08:56",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "Aw039",
                    "situF": "G",
                    "startTime": "2021-11-03T08:56:24+08:00",
                    "endTime": "2021-11-03T09:27:12.562966883+08:00",
                    "keeptime": 1848,
                    "newstartTime": "11-03 08:56",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 4,
            "NightACT": 0,
            "AllACT": 4
        },
        {
            "wlID": "L40",
            "morningData": [
                {
                    "woN": "SP202111051",
                    "situF": "R",
                    "startTime": "2021-11-03T07:49:16+08:00",
                    "endTime": "2021-11-03T08:48:58+08:00",
                    "keeptime": 3582,
                    "newstartTime": "11-03 07:49",
                    "newendTime": "11-03 08:48",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                },
                {
                    "woN": "SP202111051",
                    "situF": "G",
                    "startTime": "2021-11-03T08:48:58+08:00",
                    "endTime": "2021-11-03T09:27:12.563413944+08:00",
                    "keeptime": 2294,
                    "newstartTime": "11-03 08:48",
                    "newendTime": "11-03 09:27",
                    "shiftstartTime": "11-03 08:00",
                    "shiftendTime": "11-03 20:00"
                }
            ],
            "nightData": null,
            "MorningACT": 5,
            "NightACT": 0,
            "AllACT": 5
        }
    ]
      set_s_act(DataSocket.data.act);
      set_s_board(DataSocket.data.board);
      //console.log('DataSocket.data.act = ', DataSocket.data.act)
    }else {
      console.log('????????')
    }
  }, [DataSocket]);

  useEffect(() => {
    //console.log('s_searchData = ', s_searchData)
  }, [s_searchData]);
  
  // 切換搜尋項目(工站、時間)
  const handleChange = (type, value) => {
    const tmp = { ...s_searchData, [type]: value };
    set_s_searchData(tmp);
  }
  
  return (
    <Wrapper>

      <Title title="設備稼動總表">
        <div>
          <Select 
            style={{marginRight: '15px', width: '150px'}} 
            size='large'
            value={s_searchData.wsID}
            onChange={value => handleChange('wsID', value)}
          >
            {s_AllStation_options}
          </Select>
          <DatePicker 
            placeholder='請選擇起始日期'
            onChange={value => handleChange('date', moment(value).format('YYYY-MM-DD'))} 
            size='large'
            allowClear={false}
            style={{ width: '150px' }} 
            value={moment(s_searchData.date)}
          />
        </div>
      </Title>

      <div className='ChartContainer'>
        <div style={{width: '75%'}}>
          <LineChart s_board={s_board} width='100%' height='350px' />
        </div>
       
        <div style={{width: '24%'}}>
          <ProgressChart s_AllStation={s_AllStation} wsID={s_searchData.wsID} s_act={s_act} width='100%' height='350px' />
        </div>
      </div>

      {/* <FacetChart /> */}
      <div className='FacetChart'>
        {/* <div className='FacetChartHeader'> */}
        <div className='FacetChartContainer'>
          <div className='colHeader' style={{width: '10%'}}>線別</div>
          <div className='colHeader' style={{width: '25%', textAlign: 'center'}}>日班(08:00 - 20:00)</div>
          <div className='colHeader' style={{width: '25%', textAlign: 'center'}}>夜班(20:00 - 08:00)</div>
          <div className='colHeader' style={{width: '13.33%', textAlign: 'center'}}>日班稼動率</div>
          <div className='colHeader' style={{width: '13.33%', textAlign: 'center'}}>夜班稼動率</div>
          <div className='colHeader' style={{width: '13.33%', textAlign: 'center'}}>全天稼動率</div>
        </div>

        <div className='Container'>
          {s_act && s_act.map((item, index) => {
            //console.log('item = ', item)
            return (
              <div key={index} className='FacetChartContainer1'>
                <div className='colHeader' style={{width: '10%'}}>
                  <div className='wsBtn'>{item.wlID}</div>
                </div>
                <div className='col' style={{width: '25%'}}>
                  <TimeChart date={s_searchData.date} type='morningData' data={item.morningData} />
                </div>
                <div className='col' style={{width: '25%'}}>
                  <TimeChart date={s_searchData.date} type='nightData' data={item.nightData} />
                </div>
                <div className='col' style={{width: '13.33%', overflow: 'hidden'}}>
                  <BarChart data={item.MorningACT} />
                </div>
                <div className='col' style={{width: '13.33%', overflow: 'hidden'}}>
                  <BarChart data={item.NightACT} />
                </div>
                <div className='col' style={{width: '13.33%', overflow: 'hidden'}}>
                  <BarChart data={item.AllACT} />
                </div>
              </div>
            )
          })}
        </div>
  

      </div>
      {/* </div> */}
    </Wrapper>
  )
}

export default OverView;