import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Divider, Button } from 'antd';
import { getAllPlanInTb } from '../../../../services/WMS/PlainIn';

import CustomTable from '../../../../components/antdCustom/CustomTable';

const innerWidth = window.innerWidth;

const Footer = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`

const CycleModalContainer = ({ s_record, set_s_record, set_is_openCycleModal, call_txnProductPlanIn }) => {

  const [s_tableData, set_s_tableData] = useState([]);

  useEffect(() => {
    const call_getAllPlanInTb = async () => {
      const res = await getAllPlanInTb(s_record);

      res.status === true ? set_s_tableData(res.data) : set_s_tableData([])
    };
    call_getAllPlanInTb();
  }, [s_record]);

  const onCancel = () => {
    set_is_openCycleModal(false);
    set_s_record({});
  }

  const onSubmit = async () => {
    call_txnProductPlanIn(s_record);
    set_is_openCycleModal(false);
    set_s_record({});
  }

  const tableColumns = [
    {
      title: '過帳型態',
      dataIndex: 'setidm',
      key: 'setidm',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${150}px`,
      render: (text) => {
        return text === 'S' ? '靜態' : '動態'
      }
    },
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${250}px`,
    },
    {
      title: '動態數量',
      dataIndex: 'dqty',
      key: 'dqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${150}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '靜態數量',
      dataIndex: 'sqty',
      key: 'sqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${150}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '總數量',
      dataIndex: 'tqty',
      key: 'tqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${150}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '異動歷程',
      dataIndex: 'lifeF',
      key: 'lifeF',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${150}px`,
      render: (text, rowData, index) => {
        const keyMap = {
          "0": '開單發佈',
          "1": '入庫規劃',
          "H": '料單入庫規劃',
          "4": "出庫規劃",
          "T": "已執行更新"
        };
        return keyMap[text]
      }
    },
    {
      title: '作業時間',
      dataIndex: 'lifeFTstr',
      key: 'lifeFTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${200}px`
    },

  ];

  return (
    <div>
      <CustomTable 
        scroll={{ x: "max-content",y: window.innerHeight - 64 - 60 - 70 - 50 - 120 }}
        columns={tableColumns}
        dataSource={s_tableData.length > 0 ? s_tableData.map((item, index) => {
          return {...item, key: index}
        }) : []}
        pagination={false}
      /> 

      <Divider style={{margin: '12px 0px 12px 0px'}} />

      <Footer>

        <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={onCancel}>
          取消
        </Button>

        <Button size='large' type="primary" style={{borderRadius: '5px'}} onClick={onSubmit}>
          循環_aa
        </Button>
      </Footer>

    </div>
  )
};

export default CycleModalContainer;