import React, { useState, useEffect } from "react";
import styled from "styled-components";

import CustomTable from "../../components/antdCustom/CustomTable";

import { MyMainColorH1 } from "../../styled/publicTag";
import { Row, Col, Space, Input, message, Typography } from "antd";

import { getAllWsID, getAllProduction } from "../../services/api";

import checkUndefined from "../../utility/checkUndefined";

const WorkInProductionElement = ({ className }) => {
  const { Link } = Typography;

  const [tableData, setTableData] = useState({
    data: [],
    col: [],
    basicData: [],
  });

  // 假資料
  const aa = [
    {
      UUID: "4deffd2a-6526-42a1-962e-0b1cc375ef95",
      woN: "TEST-ROSA-0103",
      PN: "SAT.20211126.001",
      wsID: "WS01",
      inC: 0,
      ngC: 0,
      okC: 100,
    },
    {
      UUID: "d49e79d3-ec64-4fdb-8d09-de409f92927f",
      woN: "TESTD16",
      PN: "SAT.1810333.001N",
      wsID: "WS01",
      inC: 0,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "d3835230-bc32-4213-8c8f-748a810ec8b9",
      woN: "SD22011309",
      PN: "G6A.GH05A0D.11G01",
      wsID: "WS01",
      inC: 440,
      ngC: 56,
      okC: 232,
    },
    {
      UUID: "403d37fa-72a2-4b85-a6e2-9b32fac0c466",
      woN: "TM2111001",
      PN: "G6A.NT16A0M.11G01",
      wsID: "WS03",
      inC: 0,
      ngC: 0,
      okC: 1568,
    },
    {
      UUID: "cb26e182-a846-4e48-aaa7-6623df939a04",
      woN: "SD2201021",
      PN: "G6A.MAD1A0D.11G01",
      wsID: "WS01",
      inC: 84,
      ngC: 0,
      okC: 47,
    },
    {
      UUID: "085066f9-0b37-44eb-89e6-6d6ec1f1a7db",
      woN: "SD220201",
      PN: "G6A.HS01A0D.11G01",
      wsID: "WS01",
      inC: 880,
      ngC: 0,
      okC: 900,
    },
    {
      UUID: "38f116c2-61e6-4ae0-9741-923de5873e16",
      woN: "SD2202016",
      PN: "G6A.MA27A0D.11G01",
      wsID: "WS01",
      inC: 2478,
      ngC: 0,
      okC: 2400,
    },
    {
      UUID: "b9c78cb7-c8f4-47e6-97d4-640bb1db7b10",
      woN: "TD2202005",
      PN: "G6A.MAC9A0D.12G01",
      wsID: "WS01",
      inC: 106,
      ngC: 0,
      okC: 36,
    },
    {
      UUID: "b0011648-8fb5-4d3e-bb1e-6b6ba1198dc7",
      woN: "SD2202005",
      PN: "G6A.YA08A0D.11G01",
      wsID: "WS01",
      inC: 52,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "8508ad8a-418b-4283-8c42-a6d343d2ac56",
      woN: "SD2202032",
      PN: "G6A.RA09A0D.11G01",
      wsID: "WS01",
      inC: 82,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "671468e1-632a-41bf-b854-c08e0145be84",
      woN: "SD2202029",
      PN: "G6A.AJ02A0D.11G01",
      wsID: "WS01",
      inC: 44,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "e4cc085b-7a18-4eb4-98fd-ed9e6efced38",
      woN: "SD2202030",
      PN: "G6A.MA07A0D.11G01",
      wsID: "WS01",
      inC: 33,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "d975e931-be47-4d65-bab3-10f624f9da5f",
      woN: "SD2202010",
      PN: "G6A.NT01A0D.13G01",
      wsID: "WS01",
      inC: 236,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "ba3318f7-f69a-4aa0-a6a2-fda99941227f",
      woN: "SD2202002",
      PN: "G6A.NT16A0D.11G01",
      wsID: "WS01",
      inC: 234,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "7f9ff5ae-016f-4e09-b58a-362402b51ea8",
      woN: "SD2202022",
      PN: "G6A.NT09A0D.11G01",
      wsID: "WS01",
      inC: 302,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "774cb4d7-cb2c-4cef-92ef-815e377f538e",
      woN: "SD2202020",
      PN: "G6A.YA09A0D.11G01",
      wsID: "WS01",
      inC: 218,
      ngC: 0,
      okC: 0,
    },
    {
      UUID: "6381e163-fbd6-4670-b55d-2e19018a8e44",
      woN: "TESTR0002",
      PN: "SAT.YA99A0D.11G01",
      wsID: "WS02",
      inC: 0,
      ngC: 2000,
      okC: 8000,
    },
    {
      UUID: "8b8fcd83-a386-470f-8a0f-de2c8f586ee2",
      woN: "SD2202033",
      PN: "G6A.RY01A0D.11G01",
      wsID: "WS01",
      inC: 0,
      ngC: 0,
      okC: 0,
    },
  ];

  useEffect(() => {
    const call_getAllWsID = async () => {
      try {
        const res = await getAllWsID();
        if (res.status) {
          let result = [
            {
              title: "工單號",
              key: "woN",
              dataIndex: "woN",
              width: "180px",
            },
            {
              title: "料號",
              key: "PN",
              dataIndex: "PN",
              width: "190px",
            },
          ];
          for (const x of res.data) {
            result = [
              ...result,
              {
                title: x.wsID + x.wsNM,
                key: x.wsID,
                dataIndex: x.wsID,
                children: [
                  {
                    title: (
                      <span>
                        投產
                        <br />
                        數量
                      </span>
                    ),
                    key: `${x.wsID}inC`,
                    dataIndex: `${x.wsID}inC`,
                    width: "70px",
                    render: (text) => (
                      <div style={{ color: "rgb(108, 108, 108)" }}>{text}</div>
                    ),
                  },
                  {
                    title: (
                      <span>
                        不良
                        <br />
                        數量
                      </span>
                    ),
                    key: `${x.wsID}ngC`,
                    dataIndex: `${x.wsID}ngC`,
                    width: "70px",
                    render: (text) => (
                      <div style={{ color: "rgb(108, 108, 108)" }}>{text}</div>
                    ),
                  },
                  {
                    title: (
                      <span>
                        產出
                        <br />
                        數量
                      </span>
                    ),
                    key: `${x.wsID}okC`,
                    dataIndex: `${x.wsID}okC`,
                    width: "70px",
                    render: (text) => (
                      <div style={{ color: "rgb(108, 108, 108)" }}>{text}</div>
                    ),
                  },
                  {
                    title: (
                      <span>
                        未產
                        <br />
                        數量
                      </span>
                    ),
                    key: `${x.wsID}noProduce`,
                    dataIndex: `${x.wsID}noProduce`,
                    width: "70px",
                    render: (count) => (
                      <span className="noProductionCountColor">{count}</span>
                    ),
                  },
                ],
              },
            ];
          }
          const action = {
            title: <span>詳情</span>,
            key: `action`,
            dataIndex: `action`,
            render: (text, rowData, index) => {
              const key = rowData.sfcMrb;
              return <Link>成品查看</Link>;
            },
          };
          const allData = await call_getAllProduction();

          // action 最後查看成品被拿掉
          setTableData({
            ...tableData,
            col: [...result],
            data: allData,
            basicData: allData,
          });
        } else {
          message.error(res.msg);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const call_getAllProduction = async () => {
      try {
        let result = [];
        const res = await getAllProduction();
        if (res.status) {
          const data = res.data.map((x) => {
            x[`${x.wsID}inC`] = checkUndefined(x.inC);
            x[`${x.wsID}ngC`] = checkUndefined(x.ngC);
            x[`${x.wsID}okC`] = checkUndefined(x.okC);
            x[`${x.wsID}noProduce`] =
              x.inC - x.ngC - x.okC < 0
                ? "超生產"
                : checkUndefined(x.inC - x.ngC - x.okC);
            x["key"] = x.UUID;
            return x;
          });

          // 假資料
          const data1 = aa.map((x) => {
            x[`${x.wsID}inC`] = checkUndefined(x.inC);
            x[`${x.wsID}ngC`] = checkUndefined(x.ngC);
            x[`${x.wsID}okC`] = checkUndefined(x.okC);
            x[`${x.wsID}noProduce`] =
              x.inC - x.ngC - x.okC < 0
                ? "超生產"
                : checkUndefined(x.inC - x.ngC - x.okC);
            x["key"] = x.UUID;
            return x;
          });
          result = data;
        } else {
          message.error(res.msg);
        }
        return result;
      } catch (e) {
        console.error(e);
      }
    };
    call_getAllWsID();
  }, []);

  const wonSearch = (e) => {
    const keyWord = e.target.value;
    if (keyWord.length === 0) {
      setTableData({ ...tableData, data: tableData.basicData });
      return;
    }
    //模糊搜索
    const reg = new RegExp(keyWord);
    let arr = [];
    try {
      for (const x of tableData.data) {
        if (reg.test(x.woN)) {
          arr.push(x);
        }
      }
      console.log(arr);
      setTableData({ ...tableData, data: arr });
    } catch (e) {
      setTableData({ ...tableData, data: tableData.basicData });
    }
  };

  const pnSearch = (e) => {
    const keyWord = e.target.value;
    if (keyWord.length === 0) {
      setTableData({ ...tableData, data: tableData.basicData });
      return;
    }
    //模糊搜索
    const reg = new RegExp(keyWord);
    let arr = [];
    try {
      for (const x of tableData.data) {
        if (reg.test(x.PN)) {
          arr.push(x);
        }
      }
      setTableData({ ...tableData, data: arr });
    } catch (e) {
      setTableData({ ...tableData, data: tableData.basicData });
    }
  };

  return (
    <Row gutter={[12, 12]} className={className}>
      <Col span={24}>
        <MyMainColorH1>工單在製查詢</MyMainColorH1>
      </Col>
      <Col>
        <Space size={12}>
          <Input
            style={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            placeholder="請輸入工單號"
            size="large"
            onChange={wonSearch}
          />
          <Input
            stlye={{
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
            placeholder="請輸入料號"
            size="large"
            onChange={pnSearch}
          />
        </Space>
      </Col>
      <Col span={24}>
        <CustomTable
          dataSource={tableData.data}
          columns={tableData.col}
          bordered={true}
          pagination={false}
          scroll={{ x: "max-content", y: window.innerHeight - 60 - 45 - 220 }}
        />
      </Col>
    </Row>
  );
};

const WorkInProduction = styled(WorkInProductionElement)`
  .ant-table-thead > tr > th {
    text-align: center;
    /* padding: 16px; 更改antd原本設定間距 */
  }
  /* 在表格組件內固定antd寬度 設scroll={{ x: true }} 以固定寬度強制斷行 */
  .ant-table-tbody > tr > td {
    /* padding: 16px; 更改antd原本設定間距 */
  }

  /* .ant-table-thead > tr:nth-child(1) > th:nth-child(3),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(1),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(2),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(3),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(4) {
    text-align: center;
    background-color: #b0e0e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(4),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(5),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(6),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(7),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(8) {
    text-align: center !;
    background-color: #add8e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(5),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(9),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(10),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(11),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(12) {
    text-align: center;
    background-color: #b0e0e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(6),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(13),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(14),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(15),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(16) {
    text-align: center;
    background-color: #add8e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(7),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(17),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(18),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(19),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(20) {
    text-align: center;
    background-color: #b0e0e6;
  }

  .ant-table-thead > tr:nth-child(1) > th:nth-child(8),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(21),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(22),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(23),
  .ant-table-thead > tr:nth-child(2) > th:nth-child(24) {
    text-align: center;
    background-color: #add8e6;
  } */

  /* --------------------------------H原本說的----------------------------------------------- */
  /* 中文靠右 */
  /* .ant-table-tbody > tr > td {
    text-align: right;
  } */

  /* 中文靠左 */
  /* .ant-table-tbody > tr > td:nth-child(1) {
    text-align: left;
  } */

  /* ------------------------------------------------------------------------------- */

  .ant-table-tbody > tr > td {
    text-align: center;
  }

  .ant-table-tbody > tr > td:last-child {
    text-align: center;
  }

  .ant-table-tbody > tr > td:nth-child(1),
  .ant-table-tbody > tr > td:nth-child(2) {
    /* background-color: #ffffff; */
    text-align: center;
  }

  .ant-table-tbody > tr > td:nth-child(3),
  .ant-table-tbody > tr > td:nth-child(4),
  .ant-table-tbody > tr > td:nth-child(5),
  .ant-table-tbody > tr > td:nth-child(6) {
    background-color: #e7e4f3;
  }
  /* .ant-table-tbody > tr > td:nth-child(10),
  .ant-table-tbody > tr > td:nth-child(7),
  .ant-table-tbody > tr > td:nth-child(8),
  .ant-table-tbody > tr > td:nth-child(9) {
    background-color: #e8fcfc;
  } */
  .ant-table-tbody > tr > td:nth-child(14),
  .ant-table-tbody > tr > td:nth-child(11),
  .ant-table-tbody > tr > td:nth-child(12),
  .ant-table-tbody > tr > td:nth-child(13) {
    background-color: #e1eff6;
  }
  /* .ant-table-tbody > tr > td:nth-child(18),
  .ant-table-tbody > tr > td:nth-child(15),
  .ant-table-tbody > tr > td:nth-child(16),
  .ant-table-tbody > tr > td:nth-child(17) {
    background-color: #e8fcfc;
  } */
  .ant-table-tbody > tr > td:nth-child(22),
  .ant-table-tbody > tr > td:nth-child(19),
  .ant-table-tbody > tr > td:nth-child(20),
  .ant-table-tbody > tr > td:nth-child(21) {
    background-color: #f3e4e4;
  }
  /* .ant-table-tbody > tr > td:nth-child(26),
  .ant-table-tbody > tr > td:nth-child(23),
  .ant-table-tbody > tr > td:nth-child(24),
  .ant-table-tbody > tr > td:nth-child(25) {
    background-color: #e8fcfc;
  } */
  .ant-table-tbody > tr > td:nth-child(27),
  .ant-table-tbody > tr > td:nth-child(28),
  .ant-table-tbody > tr > td:nth-child(29),
  .ant-table-tbody > tr > td:nth-child(30) {
    background-color: #f6efda;
  }
  .noProductionCountColor {
    color: #e20f0f;
  }
`;

export default WorkInProduction;
