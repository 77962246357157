/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Layout, Row, Col, Space, Avatar,Popover,Tooltip, Divider} from 'antd'
import { MenuFoldOutlined ,MenuUnfoldOutlined,ExportOutlined,UserOutlined} from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { changeMenuStatus,changeTheme } from '../../redux/action'
import { SysteamName, fontSize, HeaderTag, HeaderTime,MySubtitleH4,MyH3 } from '../../styled/publicTag'
import ThemeButton from '../ThemeButton/ThemeButton'
import { antdDark, antdLight, change_antd_theme } from '../../styles/antdStyles'

const { Header } = Layout

const CustomHeader = styled(Header)`
  z-index: 2;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.1); 
  height: 60px;   
  position: fixed;
  padding: 0 24px;
  min-width: 100%;
  transition: .3s;
  top: 0;
  /* background-color: ${({ theme }) => theme.header}!important; */

  .left-container {
    display: flex;
    justify-content: flex-start;
    height: 60px;
    /* background-color: red */
  }

  .left-container .burgerMenu {
    display: flex;
    align-items: center;
  }

  .left-container .systemName {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: ${fontSize.h1};
    font-weight: 400;
    /* margin: 0 5px; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({theme}) => theme.SysteamName};

    @media screen and (max-width: 820px) {
      margin-left: 10px;
      font-size: ${fontSize.h2};
    }
  }

  .right-container {
    display: flex;
    justify-content: flex-end;
    height: 60px;
    overflow: hidden;
    /* background-color: pink */
  }

  .HeaderTag {
    font-size: ${fontSize.h3};
    overflow: hidden;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    // ipad air
    @media screen and (max-width: 820px) {
      font-size: ${fontSize.h4};
    }

    font-weight: 400;
    white-space: nowrap;
    margin: 0;
    color: ${({ theme }) => theme.HeaderTag};
  }

  .Divider {
    /* background-color: green */
  }

  .right-container .userName {
    /* background-color: gray; */
  }

  .right-container .systemTime {
    /* background-color: green; */
    
  }

  .right-container .plant {
    /* background-color: pink; */

    // ipad mini
    @media screen and (max-width: 769px) {
      /* display: none; */
    }
  }

  .right-container .plant-Divider {
    // ipad air
    @media screen and (min-width: 820px) {
      /* display: none; */
    }
  }

  .right-container .themeBtn {
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blue; */
  }
`

const MyHeader = ({user}) => { 

  const dispatch = useDispatch()

  const r_isNavBar = useSelector(allReducers => allReducers.navBar)

  const [s_time, setTime] = useState({});

  const [s_popoverVisible, setPopoverVisible] = useState(false)

  const [s_popoverOpenNumber,setPopoverOpenNumber] = useState(0)
  
  // eslint-disable-next-line
  const [s_userData,setUserData] = useState({
    username: 'Ariean',
    dept: '工程部',
    post: '員工',
    plant: '台北廠'
  })

  function getTime() {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth()
    const day = today.getDay()
    const hour = today.getHours()
    const min = today.getMinutes()
    const sec = today.getSeconds()
    // 轉成字串，如果低於10，前面加上'0'
    const monthString = (month < 10) ? ('0' + month) : ('' + month)
    const dayString = (day < 10) ? ('0' + day) : ('' + day)
    const hourString = (hour < 10) ? ('0' + hour) : ('' + hour)
    const minString = (min < 10) ? ('0' + min) : ('' + min)
    const secString = (sec < 10) ? ('0' + sec) : ('' + sec)
    let rightNow = new Date().toISOString().substring(0, 10);
    return {
      //date: `${year}-${monthString}-${dayString}`,
      date: rightNow ? rightNow : '?',
      time: `${hourString}:${minString}`
    }
  }


  useEffect(() => {
    const timer = setInterval(() => { 
      setTime(getTime())
    }, 1000)
    //畫面Rendering 完之後塞到dom 
    return () => {
      clearInterval(timer)
    }
    //只執行一次
  },[])
  //冒泡
  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation()
  }

  useEffect(() => {
    const getTheme = localStorage.getItem('theme')
    if (getTheme === 'true') {
      localStorage.setItem('theme', true)
      change_antd_theme(antdLight)
      dispatch(changeTheme(true))
    } else {
      localStorage.setItem('theme', false)
      change_antd_theme(antdDark)
      dispatch(changeTheme(false))
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const is_change_theme = () => {
    setPopoverOpenNumber(s_popoverOpenNumber+1)
    if (s_popoverOpenNumber < 1) {
      return
    }
    //antd Popover組件有問題 初次點擊時會執行裡面的function 故用useState判斷是否為初次進入頁面
    const getTheme = localStorage.getItem('theme')
    console.log('getTheme = ', getTheme)
    if (getTheme === 'true') {
      localStorage.setItem('theme', false)
      change_antd_theme(antdDark)
      dispatch(changeTheme(false))
    } else {
      localStorage.setItem('theme', true)
      change_antd_theme(antdLight)
      dispatch(changeTheme(true))
    } 
  }

  const is_navbar = (e) => { 
    // 使用 react 的 e.stopPropagation 不能阻止冒泡，需要使用 e.nativeEvent.stopImmediatePropagation，这里我们对其进行封装，方便多次调用
    stop_propagation(e)
    dispatch(changeMenuStatus(r_isNavBar))
  }

  const is_setPopoverVisible = (e) => {
    setPopoverVisible(e)
  }

  const userContent = (
    <div style={{
      width: '225px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <Avatar size={108} style={{fontSize:'56px',backgroundColor:'#5995BC'}}>{user.userName.slice(0,1)}</Avatar>
      <p style={{ fontSize: '23px', margin: '5px' }}>{user.userName}</p>
      <Space>
        <MySubtitleH4>{s_userData.dept}</MySubtitleH4>
        <MySubtitleH4>{s_userData.post}</MySubtitleH4>
      </Space>
    </div>
  )
  
  

  const headerStyle = {
    zIndex:'2',
    boxShadow:'0px 5px 3px rgba(0, 0, 0, 0.1)', 
    height: '60px',   
    position: 'fixed',
    padding: '0 24px',
    minWidth: '100%',
    transition: '.3s',
    top: 0,
    backgroundColor: '#F3F5F7'
  }

  const navBarButton = {
    fontSize:'26px'
  }
  
  const spaceStyle = {
    display:'flex',
    justifyContent: 'flex-end',
    marginRight: '50px'
  }
  //當navbar展開往右擠navbar的寬度
  const templateElementStyle = {
    marginLeft: r_isNavBar ? null : '190px',
    transition:'.3s',
  }

  const avatarStyle = {
    color: '#FEFEFE',
    backgroundColor: '#5995BC',
    marginRight: '10px'
  }
  return (
    // <Header style={headerStyle}>
    <CustomHeader>
      <Row wrap={false}>
        <Col md={9} lg={12} span={12}>
          {/* <Space
            align="baseline"
            style={templateElementStyle}
          >
            {r_isNavBar ? <MenuFoldOutlined onClick={is_navbar} style={navBarButton}/> : <MenuUnfoldOutlined onClick={is_navbar} style={navBarButton}/>}
            {(window.innerWidth > 820) ? <SysteamName>敬得生產戰情系統</SysteamName>:null}
          </Space> */}
          <div className='left-container'>
            <div className='burgerMenu'>
              {r_isNavBar ? <MenuFoldOutlined onClick={is_navbar} style={navBarButton}/> : <MenuUnfoldOutlined onClick={is_navbar} style={navBarButton}/>}
            </div>
            <div className='systemName'>
              敬得生產戰情系統
            </div>
            {/* {(window.innerWidth > 820) ? <SysteamName>敬得生產戰情系統</SysteamName>:null} */}
          </div>
        </Col>

        <Col md={15} lg={12} span={12}>{/*  style={{backgroundColor: 'red'}} */}
          <div className='right-container'>
            
            <div className='userName HeaderTag'>
              {user.userName ? user.userName : '測試姓名'}
            </div>

            <div className='Divider'>
              <Divider type='vertical' style={{height: '40px', width: '5px'}} />
            </div>
            
            <div className='systemTime HeaderTag'>
              {Object.keys(s_time).length > 0 ? <span>{s_time.date}</span> : null}
            </div>

            <div className='Divider'>
              <Divider type='vertical' style={{height: '40px', width: '5px'}} />
            </div>
            
            <div className='plant HeaderTag'>
              {user.plant ? user.plant : '觀音廠'}
            </div>

            <div className='Divider plant-Divider'>
              <Divider type='vertical' style={{height: '40px', width: '5px'}} />
            </div>
            
            <div className='themeBtn'>
              <ThemeButton onClick={is_change_theme} />
            </div>
          </div>
        </Col>
      </Row>
    {/* </Header> */}
    </CustomHeader>
  )
}
export default MyHeader
