
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import { MySubtitleH4 } from '../../../styled/publicTag';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomTabs from '../../../components/antdCustom/CustomTabs';
import CustomModal from '../../../components/antdCustom/CustomModal';

import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {getAllPlanInHd, deleteProductPlanIn, txnProductPlanIn} from '../../../services/WMS/PlainIn';

import Header from './Header';
import ModalContainer from './ModalContainer';
import CycleModalContainer from './ModalContainer/CycleModalContainer';
import TB from './TB';

import {IconCircle, IconPen, IconGarbage} from '../../../components/Icon/newicon';

const opcodeData = [
  {
    'key': '4110',
    'tabName': '生產入庫'
  },
  {
    'key': '4120',
    'tabName': '採購入庫'
  },
  {
    'key': '4130',
    'tabName': '其他入庫'
  }
];

const innerWidth = window.innerWidth;

const PlainIn = () => {

  let countRef = useRef(null);

  const [ is_openModal, set_is_openModal ] = useState(false); // 是否開啟編輯入庫循環的對話框
  const [ is_openCycleModal, set_is_openCycleModal ] = useState(false); // 是否開啟循環的對話框

  const [ s_PlainInData, set_s_PlainInData ] = useState({});  // 要被編輯的入庫資料

  const [ s_record, set_s_record ] = useState({});  // 表身亮起來的key

  const [s_tableData, set_s_tableData] = useState([]);

  const [scrollY, setScrollY] = useState("")

  const [s_searchData, set_s_searchData] = useState({    // 要搜尋的資料
    'opcodeID': '4110',
    'startDate': moment(new Date()).format('YYYY-MM-DD'),
    'endDate': moment(new Date()).format('YYYY-MM-DD'),
  });

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const tableColumns = [
    {
      title: '作業單別',
      dataIndex: 'opcode',
      key: 'opcode',
      align: 'center',
      // width: innerWidth <= 1440 ? `${100}px` : `${120}px`
      render: (text) => {
        try {
          return opcodeData.find(item => item.key === text).tabName
        } catch (e) {
          return ''
        }
        
      }
    },
    {
      title: '作業單號',
      dataIndex: 'jobN',
      key: 'jobN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${250}px`
    },
    {
      title: '來源單號碼',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${300}px`
    },
    {
      title: '倉名',
      dataIndex: 'whNM',
      key: 'whNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${200}px`
    },
    {
      title: '規劃異動量',
      dataIndex: 'planqty',
      key: 'planqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${150}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '實際異動量',
      dataIndex: 'realqty',
      key: 'realqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${150}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '帳差',
      dataIndex: 'bal',
      key: 'bal',
      align: 'center',
      width: innerWidth <= 1440 ? `${120}px` : `${130}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '異動歷程',
      dataIndex: 'lifeF',
      key: 'lifeF',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${200}px`,
      render: (text, rowData, index) => {
        const keyMap = {
          "0": '開單發佈',
          "1": '入庫規劃',
          "H": '料單入庫規劃',
          "4": "出庫規劃",
          "T": "已執行更新"
        };
        return keyMap[text]
      }
    },
    {
      title: '作業時間',
      dataIndex: 'lifeFTstr',
      key: 'lifeFTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${230}px`
    },
    // {
    //   title: '異動明細',
    //   dataIndex: 'details',
    //   key: 'details',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    //   render: (text, rowData, index) => (
    //     <div style={{display: 'flex',justifyContent: 'center'}}>
    //       <Tooltip title="查看異動明細">
    //         <Button shape="circle" icon={<SearchOutlined />} onClick={() => alert('wait')} />
    //       </Tooltip>
    //     </div>
    //   )
    // },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => {

        const cursor = rowData.lifeF === '0' ? 'pointer' : 'not-allowed';
        const color = rowData.lifeF === '0' ? 'rgb(97, 87, 97)' : '#c9c3c3';

        return (
          <div style={{display: 'flex',justifyContent: 'center', fontSize: '24px', cursor: 'pointer'}}>
              <div 
                onClick={rowData.lifeF === '1' ? (e) => recyclePlanIn(e, rowData) : null}
                style={{
                  cursor: rowData.lifeF === '1' ? 'pointer' : 'not-allowed',
                  color: rowData.lifeF === '1' ? 'rgb(97, 87, 97)' : '#c9c3c3',
                }}
              >
                <IconCircle />
              </div>
              <div onClick={rowData.lifeF === '0' ? (e) => deletePlainIn(e, rowData) : null} style={{marginLeft: '10px', cursor, color}}>
                <IconGarbage />
              </div>
          </div>
        )
      }
    },
  ]

  // 表身點擊循環icon時執行
  const recyclePlanIn = (e, rowData) => {
    e.stopPropagation(); // 這裡不要開啟表身
    
    // call_txnProductPlanIn();
    set_is_openCycleModal(true);
    
    set_s_record(rowData);
  }

  // 執行循環
  const call_txnProductPlanIn = async (rowData) => {
    const res = await txnProductPlanIn(rowData);
    res.status ? message.success(res.msg) : message.error(res.msg);
    set_s_record({});
    call_getAllPlanInHd();
  }

  // 即時計算table高度
  const getTableScroll = ({ extraHeight, ref } = {}) => {
    if (typeof extraHeight == "undefined") {
      //  默认底部分页64 + 边距10
      extraHeight = 64
    }
    let tHeader = null
    if (ref && ref.current) {
      tHeader = ref.current.getElementsByClassName("ant-table-thead")[0]
    } else {
      tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
      tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    // 窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let tmpvh = Object.keys(s_record).length > 0 ? '60vh' : '90vh';

    let height = `calc(${tmpvh} - ${tHeaderBottom + extraHeight}px)`
    // 空数据的时候表格高度保持不变,暂无数据提示文本图片居中
    if (ref && ref.current) {
      let placeholder = ref.current.getElementsByClassName('ant-table-placeholder')[0]
      if (placeholder) {
        placeholder.style.height = height
        placeholder.style.display = "flex"
        placeholder.style.alignItems = "center"
        placeholder.style.justifyContent = "center"
      }
    }
    console.log('height = ', height)
    return height
  }

  // 刪除
  const deletePlainIn = (e, rowData) => {
    e.stopPropagation(); // 這裡不要開啟表身

    Modal.confirm({
      title: '入庫資料刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的入庫資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteProductPlanIn(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        // 刪除後要更新資料
        // 取得全部入庫資料
        const call_getAllPlanInHd = async () => {
          try {
            const res = await getAllPlanInHd(s_searchData);

            if (res.status === true) {
              console.log('res.data = ', res.data)
              set_s_tableData(res.data);
            } else {
              set_s_tableData([]);
            }
            console.log('全部入庫資料 = ', res)

          } catch (e) {
            set_s_tableData([]);
          }
        }
        call_getAllPlanInHd()
      },
      cancelText: '取消',
    });
  };

  // 更改搜尋項目
  const handleChange = (type, value) => {
    
    let tmp = {}

    if(type === 'time') {
      tmp = { ...s_searchData, 'startDate': value[0], 'endDate': value[1] };
    } else {
      tmp = { ...s_searchData, [type]: value };
    }
    
    set_s_searchData(tmp);
  };
 
  // 新增
  const handleClick = () => {
    set_is_openModal(true)
  }

  const call_getAllPlanInHd = async () => {
    try {
      const res = await getAllPlanInHd(s_searchData);

      if (res.status === true) {
        console.log('res.data = ', res.data)
        set_s_tableData(res.data);
      } else {
        set_s_tableData([]);
      }
      console.log('全部入庫資料 = ', res)

    } catch (e) {
      set_s_tableData([]);
    }
  }

  // 第一次渲染跟更改搜尋資料時取得入庫單
  useEffect(() => {
    // 取得全部入庫資料
    
    call_getAllPlanInHd();
  }, [s_searchData, is_openModal])

  useEffect(() => {
    setScrollY(getTableScroll({ ref: countRef }))
    console.log('s_record = ', s_record)
  }, [s_record])

  const opcodeNM = opcodeData.find(item => item.key === s_searchData.opcodeID).tabName;

  return (
    <>
      <Title title='入庫循環'>
        <Header s_searchData={s_searchData} handleChange={handleChange} handleClick={handleClick} />
      </Title>

      <CustomTabs 
        onChange={activeKey => handleChange('opcodeID', activeKey)}
        activeKey={s_searchData.opcodeID}
        TabsData={opcodeData}
      />

      {/* 表頭 */}
      <div ref={countRef}>
        <CustomTable 
          scroll={{ x: 'max-content', y: scrollY }}
          columns={tableColumns}
          dataSource={s_tableData.length > 0 ? s_tableData.map((item, index) => {
            return {...item, key: index}
          }) : []}
          rowClassName={(record) => {
            return record.jobN === s_record.jobN ? "clickRowStyle" : "";
          }}
          onRow={(record) => {
            return {
              onClick: () => record.jobN === s_record.jobN ? set_s_record({}) : set_s_record(record)
            }
          }}
          pagination={{
            defaultCurrent: 1,
            total: pageInfo.totalItems,
            // current: abcBoardConfig.page,
            showSizeChanger: true,
            pageSize: pageInfo.pageSize,
            pageSizeOptions: ["10", "20", "30"],
            onChange: (current, size) => {
              setPageInfo({
                ...pageInfo,
                pageSize: size
              })
            },
            showTotal: (total, range) => (
              <MySubtitleH4>
                共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
              </MySubtitleH4>
            ),
          }}
        /> 
      </div>

      {/* 表身 */} 
      {Object.keys(s_record).length > 0 ? (
        <TB s_record={s_record} call_getAllPlanInHd={call_getAllPlanInHd} />
      ) : null}  

      {/* 新增入庫循環的 Modal */}
      {is_openModal ? (
        <CustomModal 
          width={innerWidth > 1440 ? '80%' : '90%'} 
          // title={Object.keys(s_PlainInData).length > 0 ? `修改${opcodeNM}` : `新建${opcodeNM}`} 
          title={Object.keys(s_PlainInData).length > 0 ? `修改${opcodeNM}資料` : `新建${opcodeNM}資料`} 
          visible={is_openModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => {
            set_is_openModal(false);
            set_s_PlainInData({});
          }}
        >
          <ModalContainer 
            opcodeID={s_searchData.opcodeID}
            set_is_openModal={set_is_openModal} 
            s_PlainInData={s_PlainInData}
            set_s_PlainInData={set_s_PlainInData} 
          />
        </CustomModal>
      ) : null}

      {/* 確認循環的 Modal */}
      
      <CustomModal 
        width={innerWidth > 1440 ? '80%' : '90%'} 
        title='循環title_aa'
        visible={is_openCycleModal}
        maskClosable={false}
        destroyOnClose
        footer={null}
        forceRender
        getContainer={false}
        // onCancel={() => {
        //   set_is_openCycleModal(false);
        //   // set_s_PlainInData({});
        // }}
      >
        {is_openCycleModal ? (
          <CycleModalContainer
            s_record={s_record}
            set_s_record={set_s_record}
            set_is_openCycleModal={set_is_openCycleModal}
            call_txnProductPlanIn={call_txnProductPlanIn}
          />
        ) : null}
      </CustomModal>
      
    </>
  )
};

export default PlainIn;
