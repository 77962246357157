import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Grid, Row, Col, Table, message, Select, Empty } from "antd";
import { MyMainColorH1 } from "../../styled/publicTag";
import Card from './Card';
import { dashboardData, getWsWl, getAllPS } from "../../services/api";
import useWebSocket from "../../utility/useWebSocket_new";
import { url } from "../../services/handlerAPI";
import moment from "moment";
import DashBox from './DashBox';


import { ReactComponent as EmptySvg } from './Empty.svg'
import Title from '../../components/Custom/Title';

const screenWidth = window.screen.width;

const Container = styled.div`
  height: calc(100vh - 150px);
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  
  // ipad mini
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Header1 = styled.div`
  display: none;
  // 圓角設定
  .ant-input, .ant-picker {
    border-radius: 15px;
  }
  // 圓角設定
  .ant-select-selector {
    border-radius: 5px!important;
  }
  
  // ipad mini
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
`

const fontSize = {
  h1: "108px",
  h2: "76px",
  h3: "74px",
  h4: "72px",
  p: "70px",
  text: "68px",
};

const DashBoardCard = () => {

  const screens = Grid.useBreakpoint();

  console.log('screens = ', screens)

  const [tableData, setTableData] = useState([]);

  const [dashboardDataSocket, changeDashboardDataParams, setDashboardData] = useWebSocket();

  const [s_boxData, set_s_boxData] = useState({});

  const [s_AllStation_options, set_s_AllStation_options] = useState([]); // 所有工站

  const [s_psID, set_s_psID] = useState('B01'); // 所有工站

  
  // websocket 連線
  useEffect(() => {
    let search = window.location.search
    const urlParams = new URLSearchParams(search);
    console.log('urlParams ============== ', urlParams.get('psID'))
    
    
    changeDashboardDataParams({
      url: url,
      apiName: "dashboard/socket",
      params: `psID=${urlParams.get('psID') ? urlParams.get('psID') : ''}`
    });
    setDashboardData(true);
  }, [s_psID]);

  // 第一次渲染取得所有車間資料, 並塞值給 s_searchData
  useEffect(() => {
    const call_getAllPS = async () => {
      try {
        const res = await getAllPS();
        if(res.status === true && res.data.length > 0) {
          //console.log('全部車間資料 = ', res.data);
          let options = res.data.map(item => {
            //style={{fontSize: `${fontSize.h1}`, padding:`300px`}}
            return(
              <Select.Option key={item.psID} value={item.psID}>{item.psNM}</Select.Option>
            )
          });
          options.push(<Select.Option key='index' value=''>全部車間</Select.Option>)
          set_s_AllStation_options(options);

          // if(res.data.length > 0) {
          //   set_s_searchData({
          //     ...s_searchData,
          //     'wsID': res.data[0].wsID
          //   });
          // };
          // set_s_psID(res.data[0].psID)
          let search = window.location.search
          const urlParams = new URLSearchParams(search);
          if(urlParams.get('psID')) {
            set_s_psID(urlParams.get('psID'))
          }
        }
        
      } catch (e) {
        console.log('---------- api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
    call_getAllPS();
  }, []);

  // realtime 更新
  useEffect(() => {
    if (dashboardDataSocket.status) {
      //message.success("看板更新成功");
      setTableData(sortData(dashboardDataSocket.data));
      const aa = [
        {
            "UUID": "9cf48814-d332-4e38-9d06-269261fc6b18",
            "wsID": "WS01",
            "wlID": "L01",
            "woN": "TEST-ROSA-1111",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10362,
            "d_planqty": 0,
            "inC": 10711,
            "d_inC": 0,
            "ngC": 0,
            "okC": 10711,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 1,
            "whC": 334338,
            "mhC": 92.9,
            "situF": "G",
            "situFT": "2021-11-16T14:19:29+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-12 17:28:23",
            "openT": "2021-11-12 17:28:23",
            "closeT": null,
            "PN": "PAK.2110092.001N",
            "pdtNM": "底盤下蓋 ABS 黑色 咬花 Dell U2422HE(cost down)",
            "sliceSN": 5133,
            "sliceT": "2021-11-16 14:20:27",
            "PPE": 135,
            "planTAR": 0,
            "emhC": 334264,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-12 17:28",
            "newopenT": "11-12 17:28",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 10362,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-100T",
            "pdtRate": "103.37%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "dab044a9-1144-4a8c-9b40-31aea87177de",
            "wsID": "WS01",
            "wlID": "L02",
            "woN": "Hjj-axw002",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 5000,
            "d_planqty": 0,
            "inC": 35372,
            "d_inC": 0,
            "ngC": 1000,
            "okC": 35372,
            "bal": 0,
            "moldcavity": 2,
            "hdC": 0,
            "whC": 0,
            "mhC": 226.1,
            "situF": "G",
            "situFT": "2021-11-16T14:19:14+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 04:12:21",
            "openT": "2021-11-07 04:12:21",
            "closeT": null,
            "PN": "PAK.2110061.101N",
            "pdtNM": "頭部旋轉蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 12496,
            "sliceT": "2021-11-16 14:20:12",
            "PPE": 130,
            "planTAR": 0,
            "emhC": 814011,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-07 04:12",
            "newopenT": "11-07 04:12",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 5000,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-100T",
            "pdtRate": "707.44%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "459ac033-72cb-4fc2-9141-4a2c7cd585ee",
            "wsID": "WS01",
            "wlID": "L03",
            "woN": "TEST-1116-0301",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 8000,
            "d_planqty": 0,
            "inC": 902,
            "d_inC": 0,
            "ngC": 0,
            "okC": 902,
            "bal": 0,
            "moldcavity": 2,
            "hdC": 2,
            "whC": 28816,
            "mhC": 4.2,
            "situF": "G",
            "situFT": "2021-11-16T14:19:39+08:00",
            "lifeF": "4",
            "lifeFT": "2021-11-16 13:32:59",
            "openT": "2021-11-16 10:09:02",
            "closeT": null,
            "PN": "SAT-TEST-01",
            "pdtNM": "SAT測試使用",
            "sliceSN": 229,
            "sliceT": "2021-11-16 14:20:37",
            "PPE": 197,
            "planTAR": 0,
            "emhC": 15035,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-16 13:32",
            "newopenT": "11-16 10:09",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 8000,
            "mappedLifeF": "報復工",
            "brand": "全力發",
            "model": "CLF-100T",
            "pdtRate": "11.28%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "454adafc-4d08-470f-aa2a-1dd2678cdac0",
            "wsID": "WS01",
            "wlID": "L04",
            "woN": "Hjj-axw004",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 5000,
            "d_planqty": 0,
            "inC": 18688,
            "d_inC": 0,
            "ngC": 0,
            "okC": 18688,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 226.1,
            "situF": "G",
            "situFT": "2021-11-16T14:20:14+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 04:14:04",
            "openT": "2021-11-07 04:14:04",
            "closeT": null,
            "PN": "PAK.2110081.101N",
            "pdtNM": "底盤上蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 12496,
            "sliceT": "2021-11-16 14:21:12",
            "PPE": 96,
            "planTAR": 0,
            "emhC": 813968,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:20",
            "newlifeFT": "11-07 04:14",
            "newopenT": "11-07 04:14",
            "newcloseT": "",
            "newsliceT": "11-16 14:21",
            "target": 5000,
            "mappedLifeF": "開始生產",
            "brand": "海太",
            "model": "110T",
            "pdtRate": "373.76%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "d741c8ef-d847-4994-b420-c80b76f9553b",
            "wsID": "WS01",
            "wlID": "L05",
            "woN": "Hjj-axwsm005",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 12440,
            "d_inC": 0,
            "ngC": 0,
            "okC": 12440,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 2,
            "whC": 816671,
            "mhC": 113.5,
            "situF": "G",
            "situFT": "2021-11-16T14:19:49+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-11 20:56:21",
            "openT": "2021-11-11 20:56:21",
            "closeT": null,
            "PN": "PSK.2110061.001N",
            "pdtNM": "頭部旋轉蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 6268,
            "sliceT": "2021-11-16 14:20:47",
            "PPE": 76,
            "planTAR": 0,
            "emhC": 408206,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-11 20:56",
            "newopenT": "11-11 20:56",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "中台精機",
            "model": "180T",
            "pdtRate": "124.40%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "1b930765-e7e3-45c5-b73d-9ef04bcc26f3",
            "wsID": "WS01",
            "wlID": "L06",
            "woN": "Hjj-axwsm006",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 50000,
            "d_planqty": 0,
            "inC": 17324,
            "d_inC": 0,
            "ngC": 0,
            "okC": 17324,
            "bal": 0,
            "moldcavity": 2,
            "hdC": 0,
            "whC": 0,
            "mhC": 63.6,
            "situF": "G",
            "situFT": "2021-11-16T14:19:14+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-13 22:47:03",
            "openT": "2021-11-13 22:47:03",
            "closeT": null,
            "PN": "PSK.2110051.001N",
            "pdtNM": "VESA蓋 ABS 灰色 液烤  Dell U2422HE(soct down)",
            "sliceSN": 3513,
            "sliceT": "2021-11-16 14:20:12",
            "PPE": 189,
            "planTAR": 0,
            "emhC": 228729,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-13 22:47",
            "newopenT": "11-13 22:47",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 50000,
            "mappedLifeF": "開始生產",
            "brand": "中台精機",
            "model": "180T",
            "pdtRate": "34.65%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "d0368697-7dd0-4995-9d56-08a58a95a1ab",
            "wsID": "WS01",
            "wlID": "L07",
            "woN": "Hjj-axwsm007",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 21438,
            "d_inC": 0,
            "ngC": 0,
            "okC": 21438,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 210.3,
            "situF": "G",
            "situFT": "2021-11-16T14:20:14+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 20:06:00",
            "openT": "2021-11-07 20:06:00",
            "closeT": null,
            "PN": "PAK.2110081.101N",
            "pdtNM": "底盤上蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 11621,
            "sliceT": "2021-11-16 14:21:12",
            "PPE": 119,
            "planTAR": 0,
            "emhC": 756852,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:20",
            "newlifeFT": "11-07 20:06",
            "newopenT": "11-07 20:06",
            "newcloseT": "",
            "newsliceT": "11-16 14:21",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-180T",
            "pdtRate": "214.38%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "12d630fe-a14e-42e0-b954-a461a3e9078e",
            "wsID": "WS01",
            "wlID": "L08",
            "woN": "Hjj-axwsm008",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 22631,
            "d_inC": 0,
            "ngC": 0,
            "okC": 22631,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 210.2,
            "situF": "G",
            "situFT": "2021-11-16T14:20:09+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 20:06:51",
            "openT": "2021-11-07 20:06:51",
            "closeT": null,
            "PN": "PAK.2110081.101N",
            "pdtNM": "底盤上蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 11619,
            "sliceT": "2021-11-16 14:21:07",
            "PPE": 126,
            "planTAR": 0,
            "emhC": 756796,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:20",
            "newlifeFT": "11-07 20:06",
            "newopenT": "11-07 20:06",
            "newcloseT": "",
            "newsliceT": "11-16 14:21",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-200T",
            "pdtRate": "226.31%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "99d58117-1336-4cbd-986a-8143b6f36ff7",
            "wsID": "WS01",
            "wlID": "L09",
            "woN": "Hjj-axwsm009",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 21546,
            "d_inC": 0,
            "ngC": 0,
            "okC": 21546,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 210.2,
            "situF": "G",
            "situFT": "2021-11-16T14:19:54+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 20:07:44",
            "openT": "2021-11-07 20:07:44",
            "closeT": null,
            "PN": "PAK.2110081.101N",
            "pdtNM": "底盤上蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 11618,
            "sliceT": "2021-11-16 14:20:52",
            "PPE": 120,
            "planTAR": 0,
            "emhC": 756728,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-07 20:07",
            "newopenT": "11-07 20:07",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "富強鑫",
            "model": "260T",
            "pdtRate": "215.46%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "8d75e998-a2d2-480f-ba65-62047a5f740e",
            "wsID": "WS01",
            "wlID": "L10",
            "woN": "TEST-1111-1001",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 1501,
            "d_planqty": 0,
            "inC": 925,
            "d_inC": 0,
            "ngC": 0,
            "okC": 925,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 1,
            "whC": 27650,
            "mhC": 7.7,
            "situF": "W",
            "situFT": "2021-11-11T18:20:26+08:00",
            "lifeF": "7",
            "lifeFT": "2021-11-11 18:20:26",
            "openT": "2021-11-11 10:39:43",
            "closeT": "2021-11-11 18:20:26",
            "PN": "SAT-TEST-01",
            "pdtNM": "SAT測試使用",
            "sliceSN": 425,
            "sliceT": "2021-11-11 18:20:08",
            "PPE": 100,
            "planTAR": 0,
            "emhC": 27568,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-11 18:20",
            "newlifeFT": "11-11 18:20",
            "newopenT": "11-11 10:39",
            "newcloseT": "11-11 18:20",
            "newsliceT": "11-11 18:20",
            "target": 1501,
            "mappedLifeF": "報完工",
            "brand": "富強鑫",
            "model": "260T",
            "pdtRate": "61.63%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "28f714a6-bb82-4c68-b079-8903523828bd",
            "wsID": "WS01",
            "wlID": "L11",
            "woN": "Hjj-axwsm011",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 20160,
            "d_inC": 0,
            "ngC": 0,
            "okC": 20160,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 210.1,
            "situF": "G",
            "situFT": "2021-11-16T14:19:39+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 20:11:56",
            "openT": "2021-11-07 20:11:56",
            "closeT": null,
            "PN": "PAK.2110071.101N",
            "pdtNM": "塑膠支架 ABS 灰色 咬花+液烤 Dell U2422H",
            "sliceSN": 11615,
            "sliceT": "2021-11-16 14:20:37",
            "PPE": 120,
            "planTAR": 0,
            "emhC": 756461,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-07 20:11",
            "newopenT": "11-07 20:11",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-280T",
            "pdtRate": "201.60%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "2adce0b6-e969-45f5-9e01-159cfd86df13",
            "wsID": "WS01",
            "wlID": "L12",
            "woN": "Hjj-axwsm012",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 19368,
            "d_inC": 0,
            "ngC": 0,
            "okC": 19368,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 210.2,
            "situF": "R",
            "situFT": "2021-11-16T14:19:19+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 20:10:56",
            "openT": "2021-11-07 20:10:56",
            "closeT": null,
            "PN": "PAK.2110092.001N",
            "pdtNM": "底盤下蓋 ABS 黑色 咬花 Dell U2422HE(cost down)",
            "sliceSN": 11616,
            "sliceT": "2021-11-16 14:20:17",
            "PPE": 108,
            "planTAR": 0,
            "emhC": 756501,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-07 20:10",
            "newopenT": "11-07 20:10",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "中台精機",
            "model": "260T",
            "pdtRate": "193.68%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "5feeb68e-1e8d-4f10-ad76-903502c18de1",
            "wsID": "WS01",
            "wlID": "L13",
            "woN": "Hjj-axw013",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 5000,
            "d_planqty": 0,
            "inC": 21407,
            "d_inC": 0,
            "ngC": 0,
            "okC": 21407,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 225.9,
            "situF": "G",
            "situFT": "2021-11-16T14:19:24+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 04:25:00",
            "openT": "2021-11-07 04:25:00",
            "closeT": null,
            "PN": "PAK.2110071.101N",
            "pdtNM": "塑膠支架 ABS 灰色 咬花+液烤 Dell U2422H",
            "sliceSN": 12486,
            "sliceT": "2021-11-16 14:20:22",
            "PPE": 118,
            "planTAR": 0,
            "emhC": 813262,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-07 04:25",
            "newopenT": "11-07 04:25",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 5000,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-260T",
            "pdtRate": "428.14%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "feebea85-a366-4116-9bf2-168ecabe2e30",
            "wsID": "WS01",
            "wlID": "L14",
            "woN": "Hjj-asm14",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 500,
            "d_planqty": 0,
            "inC": 2725,
            "d_inC": 0,
            "ngC": 0,
            "okC": 2725,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 41,
            "situF": "W",
            "situFT": "2021-11-07T20:34:48+08:00",
            "lifeF": "7",
            "lifeFT": "2021-11-07 20:34:48",
            "openT": "2021-11-06 03:33:49",
            "closeT": "2021-11-07 20:34:48",
            "PN": "PAK.2110092.001N",
            "pdtNM": "底盤下蓋 ABS 黑色 咬花 Dell U2422HE(cost down)",
            "sliceSN": 2256,
            "sliceT": "2021-11-07 20:18:04",
            "PPE": 78,
            "planTAR": 0,
            "emhC": 146598,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-07 20:34",
            "newlifeFT": "11-07 20:34",
            "newopenT": "11-06 03:33",
            "newcloseT": "11-07 20:34",
            "newsliceT": "11-07 20:18",
            "target": 500,
            "mappedLifeF": "報完工",
            "brand": "全力發",
            "model": "CLF-260T",
            "pdtRate": "545.00%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "a758ac89-e469-4d0e-bea6-617e43476ccd",
            "wsID": "WS01",
            "wlID": "L15",
            "woN": "TEST-1111-1501",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 1500,
            "d_planqty": 0,
            "inC": 1424,
            "d_inC": 0,
            "ngC": 0,
            "okC": 1424,
            "bal": 0,
            "moldcavity": 2,
            "hdC": 1,
            "whC": 26340,
            "mhC": 7.3,
            "situF": "W",
            "situFT": "2021-11-11T18:20:17+08:00",
            "lifeF": "7",
            "lifeFT": "2021-11-11 18:20:17",
            "openT": "2021-11-11 11:01:22",
            "closeT": "2021-11-11 18:20:17",
            "PN": "PAK.2110352.001N",
            "pdtNM": "VESA蓋 ABS 黑色 咬花 Dell P2422H (cost down) （4#）",
            "sliceSN": 404,
            "sliceT": "2021-11-11 18:19:48",
            "PPE": 243,
            "planTAR": 0,
            "emhC": 26249,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-11 18:20",
            "newlifeFT": "11-11 18:20",
            "newopenT": "11-11 11:01",
            "newcloseT": "11-11 18:20",
            "newsliceT": "11-11 18:19",
            "target": 1500,
            "mappedLifeF": "報完工",
            "brand": "全力發",
            "model": "CLF-260T",
            "pdtRate": "94.93%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "7492ce12-e955-4206-9746-7fa912e66be9",
            "wsID": "WS01",
            "wlID": "L16",
            "woN": "Hjj-awsm016",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 5000,
            "d_planqty": 0,
            "inC": 5464,
            "d_inC": 1674,
            "ngC": 0,
            "okC": 5464,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 71.6,
            "situF": "W",
            "situFT": "2021-11-07T20:34:18+08:00",
            "lifeF": "7",
            "lifeFT": "2021-11-07 20:34:18",
            "openT": "2021-11-04 20:55:21",
            "closeT": "2021-11-07 20:34:18",
            "PN": "PAK.2110081.101N",
            "pdtNM": "底盤上蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 3947,
            "sliceT": "2021-11-07 20:17:14",
            "PPE": 62,
            "planTAR": 0,
            "emhC": 256855,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-07 20:34",
            "newlifeFT": "11-07 20:34",
            "newopenT": "11-04 20:55",
            "newcloseT": "11-07 20:34",
            "newsliceT": "11-07 20:17",
            "target": 3326,
            "mappedLifeF": "報完工",
            "brand": "全力發",
            "model": "CLF-280T",
            "pdtRate": "113.95%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "7b2cb0a6-53ef-4615-a3b2-65b64a814fc8",
            "wsID": "WS01",
            "wlID": "L17",
            "woN": "TEST-1115-1701",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 1500,
            "d_planqty": 0,
            "inC": 1334,
            "d_inC": 0,
            "ngC": 0,
            "okC": 1334,
            "bal": 0,
            "moldcavity": 2,
            "hdC": 0,
            "whC": 0,
            "mhC": 7.2,
            "situF": "W",
            "situFT": "2021-11-15T18:05:35+08:00",
            "lifeF": "7",
            "lifeFT": "2021-11-15 18:05:35",
            "openT": "2021-11-15 10:51:49",
            "closeT": "2021-11-15 18:05:35",
            "PN": "SAT-TEST-01",
            "pdtNM": "SAT測試使用",
            "sliceSN": 401,
            "sliceT": "2021-11-15 18:04:37",
            "PPE": 169,
            "planTAR": 0,
            "emhC": 25911,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-15 18:05",
            "newlifeFT": "11-15 18:05",
            "newopenT": "11-15 10:51",
            "newcloseT": "11-15 18:05",
            "newsliceT": "11-15 18:04",
            "target": 1500,
            "mappedLifeF": "報完工",
            "brand": "全力發",
            "model": "CLF-280T",
            "pdtRate": "88.93%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "a4cd590d-4d9d-4a58-af6a-d3a5455c2d88",
            "wsID": "WS01",
            "wlID": "L18",
            "woN": "Hjj-axwsm018",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 19751,
            "d_inC": 0,
            "ngC": 0,
            "okC": 19751,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 210.1,
            "situF": "G",
            "situFT": "2021-11-16T14:19:14+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 20:15:51",
            "openT": "2021-11-07 20:15:51",
            "closeT": null,
            "PN": "PAK.2110092.001N",
            "pdtNM": "底盤下蓋 ABS 黑色 咬花 Dell U2422HE(cost down)",
            "sliceSN": 11611,
            "sliceT": "2021-11-16 14:20:12",
            "PPE": 110,
            "planTAR": 0,
            "emhC": 756201,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-07 20:15",
            "newopenT": "11-07 20:15",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-280T",
            "pdtRate": "197.51%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "9c6bdb6e-6011-438c-8c4a-87a31e520049",
            "wsID": "WS01",
            "wlID": "L19",
            "woN": "Hjj-axwsm019",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 14701,
            "d_inC": 0,
            "ngC": 0,
            "okC": 14701,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 210.1,
            "situF": "G",
            "situFT": "2021-11-16T14:19:44+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 20:17:01",
            "openT": "2021-11-07 20:17:01",
            "closeT": null,
            "PN": "PAK.2110081.101N",
            "pdtNM": "底盤上蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 11611,
            "sliceT": "2021-11-16 14:20:42",
            "PPE": 82,
            "planTAR": 0,
            "emhC": 756161,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-07 20:17",
            "newopenT": "11-07 20:17",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-300T",
            "pdtRate": "147.01%",
            "ngRate": "0.00%"
        },
        {
            "UUID": "b029e9d9-3153-460b-8cd1-768e2d9cb758",
            "wsID": "WS01",
            "wlID": "L20",
            "woN": "Hjj-axwsm020",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 10000,
            "d_planqty": 0,
            "inC": 14877,
            "d_inC": 0,
            "ngC": 0,
            "okC": 14877,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 210,
            "situF": "G",
            "situFT": "2021-11-16T14:19:14+08:00",
            "lifeF": "2",
            "lifeFT": "2021-11-07 20:18:13",
            "openT": "2021-11-07 20:18:13",
            "closeT": null,
            "PN": "PAK.2110081.101N",
            "pdtNM": "底盤上蓋 ABS 灰色 液烤 Dell U2422HE(cost down)",
            "sliceSN": 11609,
            "sliceT": "2021-11-16 14:20:12",
            "PPE": 83,
            "planTAR": 0,
            "emhC": 756059,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "11-16 14:19",
            "newlifeFT": "11-07 20:18",
            "newopenT": "11-07 20:18",
            "newcloseT": "",
            "newsliceT": "11-16 14:20",
            "target": 10000,
            "mappedLifeF": "開始生產",
            "brand": "全力發",
            "model": "CLF-350T",
            "pdtRate": "148.77%",
            "ngRate": "0.00%"
        }
    ]
    // setTableData(sortData(aa));
    // setTableData(aa);
    if(dashboardDataSocket.data && dashboardDataSocket.data.length > 0) {
      // 開線數
      const openLine = dashboardDataSocket.data.filter(item => item.situF !== 'W').length;
      // 總線數
      const allLine = dashboardDataSocket.data.length;
      // 開線率
      const openLineRate = `${(openLine/allLine*100).toFixed(1)}%`;

      set_s_boxData({ openLine, allLine, openLineRate });
    }
    } else {
      //message.error("看板更新失敗");
    }
    console.log('看板資料 = ', dashboardDataSocket);
  }, [dashboardDataSocket]);
  console.log('tableData = ', tableData)

  const sortData = (data) => {
    try {
      const newData = data.map((x) => {
        return {
          ...x,
          openT: sortTime(x.openT),
          closeT: sortTime(x.closeT),
          lifeFT: sortTime(x.lifeFT),
          sliceT: sortTime(x.sliceT),
          mhC: formatFloat(x.mhC, 1),
        };
      });
      return newData;
    } catch (e) {
      console.log('------------ sortData error ------------');
      return [];
    }
  };
  
  const sortTime = (time) => {
    var newTime = moment(time).format("YYYY-MM-DD HH:mm:ss");
    if (moment(newTime).year() < 2000) {
      return null;
    } else {
      return newTime;
    }
  }

  const formatFloat = (num, pos) => {
    var size = Math.pow(10, pos);
    return Math.round(num * size) / size;
  }
  const handleChange = (value) => {
    set_s_psID(value);
    // const urlParams = new URLSearchParams(window.location.search);
    // console.log('urlParams = ', urlParams)
    // urlParams.set('order', 'date');
    // window.location.search = urlParams;

    var url = `${window.location.pathname}?psID=${value}`
    window.history.pushState({url: url}, '', url)
  }

  return (
    <div style={{marginLeft: '15px', marginTop: '15px'}}>
      {/* <MyMainColorH1>數位生產圖框</MyMainColorH1> */}
      <Title title='數位生產看板'>
        <Header>
          <DashBox header='開線率' value={Object.keys(s_boxData).length > 0 ? s_boxData.openLineRate : ''}></DashBox>
          <DashBox header='開線數' value={Object.keys(s_boxData).length > 0 ? s_boxData.openLine : ''} />
          <DashBox header='總線數' value={Object.keys(s_boxData).length > 0 ? s_boxData.allLine : ''}></DashBox>
          <Select 
            // style={{marginRight: '15px'}} 
            disabled
            size='large'
            value={s_psID}
            onChange={value => handleChange(value)}
          >
            {s_AllStation_options}
          </Select>
        </Header>
      </Title>

      <Header1>
        <DashBox header='開線率' value={Object.keys(s_boxData).length > 0 ? s_boxData.openLineRate : ''}></DashBox>
        <DashBox header='開線數' value={Object.keys(s_boxData).length > 0 ? s_boxData.openLine : ''} />
        <DashBox header='總線數' value={Object.keys(s_boxData).length > 0 ? s_boxData.allLine : ''}></DashBox>
        <Select 
          // style={{marginRight: '15px'}} 
          disabled
          size='large'
          value={s_psID}
          onChange={value => handleChange(value)}
        >
          {s_AllStation_options}
        </Select>
      </Header1>

      <Container>
        <Row gutter={[24, 12]}>
          {tableData.length > 0 ? (
            tableData.map((item, index) => {
              return (
                screenWidth >= 1920 ? (
                  <Col key={index} style={{width: '20%'}}>
                    <Card data={item} />
                  </Col>
                ) : screenWidth >= 1440 ? (
                  <Col key={index} span={8}>
                    <Card data={item} />
                  </Col>
                ) : 
                  screenWidth >= 1024 ? (
                    <Col key={index} span={8}>
                      <Card data={item} />
                    </Col>
                  ) : (
                    <Col key={index} span={12}>
                      <Card data={item} />
                    </Col>
                  )
              )
              // return (
              //   <Col key={index} lg={6} md={12}>
              //     <Card data={item} />
              //   </Col>
              // )
              // return (
              //   screenWidth > 1920 ? (
              //     <Col key={index} style={{width: '20%'}}>
              //       <Card data={item} />
              //     </Col>
              //   ) : (
              //   <Col key={index} span={6}>
              //     <Card data={item} />
              //   </Col>

              //   )
              // )
            })
          ) : (
            <div style={{marginTop: '40px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              {/* <CustomEmpty 
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                  width: 600,
                  height: '500px'
                }}
              /> */}
              <EmptySvg width={300} height={250} />
              <span style={{color: '#D9D9D9', fontSize: '30px'}}>查無資料</span>
            </div>
          )}
        </Row> 
      </Container>
    </div>
  );

};

export default DashBoardCard;