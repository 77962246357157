import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Form,
  Button,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Divider,
  message,
  InputNumber,
} from "antd";
import { getMold, moldUpdate } from "../../../services/api";
const StyledForm = styled(Form)`
  .ant-input,
  .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px !important;
  }
`;
const ModalContainer = ({ s_editData, set_s_editData, set_s_showModal }) => {
  const [form] = Form.useForm();

  const [s_AllMold, set_s_AllMold] = useState([]);

  const handleCancel = () => {
    set_s_showModal(false);
  };

  const onSubmit = async (values) => {
    console.log({
      ...values,
      UUID: s_editData.UUID,
    });
    // return;
    const res = await moldUpdate({
      ...values,
      UUID: s_editData.UUID,
    });
    if (res.status === true) {
      message.success(res.msg);
    } else {
      message.error(res.msg);
    }
    set_s_showModal(false);
  };

  useEffect(() => {
    // 取得所有模具資料
    const call_getAllMould = async () => {
      try {
        const res = await getMold(s_editData.PN);

        if (res.data) {
          console.log("全部模具資料 = ", res.data);
          const tmpData = res.data.map((item, index) => {
            return {
              ...item,
              key: index,
            };
          });
          console.log("tmpData = ", tmpData);
          set_s_AllMold(tmpData);
        }
      } catch (e) {
        console.log("---------- api getAllStaffPerm error ----------");
        console.log(e);
      }
    };
    call_getAllMould();
  }, []);

  return (
    <StyledForm
      name="basic"
      form={form}
      onFinish={onSubmit}
      autoComplete="off"
      layout="vertical" // horizontal
    >
      <Row>
        <Col span={12}>
          <Form.Item
            labelCol={{ offset: 2 }}
            wrapperCol={{ span: 20, offset: 2 }}
            label={<label style={{ color: "#6C6C6C" }}>模具</label>}
            name="moldID"
            rules={[{ required: true, message: "請選擇模具!" }]}
          >
            <Select placeholder="選擇模具">
              {s_AllMold.length > 0 &&
                s_AllMold.map((item) => {
                  return (
                    <Select.Option key={item.key} value={item.moldID}>
                      {item.moldID}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            labelCol={{ offset: 2 }}
            wrapperCol={{ span: 20, offset: 2 }}
            label={<label style={{ color: "#6C6C6C" }}>標準週期</label>}
            name="cycletimemh"
            rules={[{ required: true, message: "請選擇標準週期!" }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Divider
        style={{
          marginBottom: "20px",
          borderTop: "1.5px solid rgba(0,0,0,0.06)",
        }}
      />

      <Row>
        <Col offset={16} span={8}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="large"
              style={{ marginRight: "20px", borderRadius: "5px" }}
              onClick={handleCancel}
            >
              取消
            </Button>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ marginRight: "30px", borderRadius: "5px" }}
              >
                確定
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default ModalContainer;
